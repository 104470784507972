import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { formatDateToMMDDYYY, formatUnixTimeStampToFromNow } from "../utils/datetime";
import { MdTaskAlt } from "react-icons/md";
import { HighlightOff } from "@material-ui/icons";
import Loader, { SmallLoader } from "./FullScreenLoader";

const styles = (theme) => ({
  msgcontainer: {
    padding: "0px!important",
    borderLeft: "1px solid #c9c9c9",
    borderRight: "1px solid #c9c9c9",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
  notificationContent: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      //height: "120px",
    },
    [theme.breakpoints.up("sm")]: {
      //height: "95px",
    },
    backgroundColor: "#ebf5ff",
    color: "#2e2e2e",
    fontSize: "15px",
    fontWeight: "500",
    padding: "16px 15px",
    justifyContent: "space-between",
    marginBottom: "8px",
    borderBottom: "1px solid #c9c9c9",
    whiteSpace: "normal",
  },

  notificationMessage: {
    flex: "8",
    marginLeft: "30px",
    display: "flex",
    justifyContent: "space-between",
  },
  notificationText: {
    margin: "0px",
    marginBottom: "10px",
    // wordWrap: 'break-word'
  },
  ellipsesIcon: {
    marginRight: "3px",
  },
  count: {
    marginRight: "15px",
  },
  noteIcon: {
    marginRight: "5px",
  },
  commentIcon: {
    marginRight: "5px",
  },
  root: {
    // minWidth: '700px',
    minWidth: "100%",
  },
  main: {
    width: "100%",
    flex: 1,
  },
});

function UserNotification(props) {
  const { classes, notification, acceptClick, declineClick, loading } = props;

  return (
    <div className={classes.root}>
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item xs zeroMinWidth className={classes.msgcontainer}>
          <Typography noWrap className={classes.notificationContent}>
            {/*   <div className={classes.notificationFromImage}>
              <img src="../../public/assets/notifier.png" />
            </div> */}
            <div className={classes.notificationMessage}>
              <div>
                <p
                  style={{ marginBottom: 0 }}
                  className={classes.notificationText}
                >
                  {notification.text}
                </p>
                <div style={{ color: "#b9c4f9" }}>
                  {formatUnixTimeStampToFromNow(notification.createdAt)}
                </div>
              </div>
              {notification.actionCompletedIdToUpdate &&
                !notification.isActionTaken && (
                  <div>
                    {loading ? (
                      <Loader width={30} strokeWidthSecondary={5} />
                    ) : (
                      <>
                        <MdTaskAlt
                          onClick={acceptClick}
                          style={{
                            width: "2rem",
                            cursor: "pointer",
                            height: "1.5rem",
                            marginRight: "0.5rem",
                            marginLeft: "1rem",
                          }}
                        />
                        <HighlightOff
                          onClick={declineClick}
                          style={{ cursor: "pointer" }}
                        />
                      </>
                    )}
                  </div>
                )}
            </div>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

UserNotification.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(UserNotification);
