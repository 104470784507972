import React from "react";
import Basic from "../../components/FullScreenCalendar";
import { Post } from "../PageHome/Feed";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Grid } from "react-loader-spinner";
import Layout from "../../components/Layout";
import CommunityEvent from "./CommunityEvent";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  count: {
    marginRight: "15px",
  },
  root: {
    // minWidth: '700px',
    // background: theme.palette.background.secondary.main,
    minWidth: "100%",
  },
  summaryList: {
    display: "flex",
    justifyContent: "space-between",
  },
  Listtitle: {
    fontSize: "15px",
    fontWeight: "500px",
    color: "#0073b1",
    display: "flex",
    justifyContent: "center",
  },
  expandListItems: {
    borderTop: "2px solid #efefef",
    padding: "10px 0 0 0",
    margin: "10px 0 0 0",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  expandListTitle: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#283e4a",
  },
  newsCard: {
    marginTop: "10px",
  },
  newsTitle: {
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    fontWeight: "400",
    color: "#0073b1",
  },
  newsContent: {
    fontWeight: "400",
    color: "#707070",
    fontSize: "14px",
  },
  projectToolCard: {
    marginTop: "10px",
  },
  projectToolTitle: {
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    fontWeight: "400",
    color: "#0073b1",
  },
  projectToolContent: {
    fontWeight: "400",
    color: "#707070",
    fontSize: "14px",
  },
  aside: {
    width: "100%",
  },
  profile: {
    width: "100%",
  },
  rightSide: {
    width: "100%",
  },
  main: {
    width: "100%",
    flex: 1,
  },
});

function EventCalendar({ classes }) {
  const [value, setValue] = React.useState("box");

  return (
    <>
      <CommunityEvent />
    </>
  );
}

export const Switcher = ({ value, setValue }) => {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        style={{ display: "flex", flexDirection: "row" }}
        aria-label="gender"
        name="gender1"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value="box" control={<Radio />} label="Box" />
        <FormControlLabel
          value="calendar"
          control={<Radio />}
          label="Calendar"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default withStyles(styles)(EventCalendar);
