import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { useTheme } from "@material-ui/core";
// import { ThemeProvider } from "@material-ui/core";

export function StartPostIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 36.029 29.475">
      <path
        id="Path_25"
        data-name="Path 25"
        d="M26.5,36.54H6.522V16.524h9.99V15.4H5.4V37.664H27.625V25.183H26.5Z"
        transform="translate(-5.4 -13.128)"
        fill={props.fillColor}
        stroke={props.fillColor}
        stroke-width="1"
      />
      <path
        id="Path_26"
        data-name="Path 26"
        d="M20.968,14.331,20.5,17.248l2.917-.468.29-.312h0L32.57,7.627,30.121,5.2l-8.841,8.841Zm.6.468,1.358,1.381-1.648.267ZM31.613,7.627l-.869.891-1.47-1.492.891-.869Zm-2.828-.134,1.492,1.492-6.815,6.792-1.47-1.492Z"
        transform="translate(-8.14 -5.2)"
        fill={props.fillColor}
        stroke={props.fillColor}
        stroke-width="1"
      />
    </SvgIcon>
  );
}

export function TeamMessageIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 36.029 29.475">
      <g
        id="Group_1207"
        data-name="Group 1207"
        transform="translate(334.631 -112)"
      >
        <path
          id="Icon_material-group"
          data-name="Icon material-group"
          d="M15.354,13.86a2.985,2.985,0,0,0,2.762-3.18A2.985,2.985,0,0,0,15.354,7.5a2.992,2.992,0,0,0-2.771,3.18A2.992,2.992,0,0,0,15.354,13.86Zm-7.389,0a2.985,2.985,0,0,0,2.762-3.18A2.985,2.985,0,0,0,7.965,7.5a2.992,2.992,0,0,0-2.771,3.18A2.992,2.992,0,0,0,7.965,13.86Zm0,2.12c-2.152,0-6.465,1.24-6.465,3.71v2.65H14.43v-2.65C14.43,17.22,10.117,15.98,7.965,15.98Zm7.389,0c-.268,0-.573.021-.9.053a4.643,4.643,0,0,1,1.819,3.657v2.65h5.542v-2.65C21.819,17.22,17.506,15.98,15.354,15.98Z"
          transform="translate(-331.859 109.033)"
          fill={props.fillColor}
        />
        <path
          id="Icon_feather-message-square"
          data-name="Icon feather-message-square"
          d="M4.5,22.587A3.014,3.014,0,0,0,7.514,25.6H25.6L31.63,31.63V7.514A3.014,3.014,0,0,0,28.616,4.5H7.514A3.014,3.014,0,0,0,4.5,7.514Z"
          transform="translate(-338.131 108.5)"
          fill="none"
          stroke={props.fillColor}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </SvgIcon>
  );
}

export function NotificationIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 36.029 29.475">
      <path
        id="Icon_ionic-md-alarm"
        data-name="Icon ionic-md-alarm"
        d="M26.043,7.422,20.732,3.01l-1.5,1.777L24.543,9.2ZM9.707,4.729l-1.5-1.776L2.953,7.422,4.454,9.2ZM15.075,10H13.344v6.876l5.484,3.266.866-1.432-4.618-2.693V10ZM14.5,5.417A10.314,10.314,0,1,0,24.889,15.73,10.345,10.345,0,0,0,14.5,5.417Zm0,18.335A8.022,8.022,0,1,1,22.58,15.73,8.075,8.075,0,0,1,14.5,23.751Z"
        transform="translate(-2.953 -2.953)"
        fill={props.fillColor}
      />
    </SvgIcon>
  );
}

export function DashboardIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        id="Path_18"
        data-name="Path 18"
        d="M21.642,14.2l-1.816-.66v-.68l.261-.41a4.847,4.847,0,0,0,.783-2.63V9a3.133,3.133,0,0,0-6.261,0v.82a4.847,4.847,0,0,0,.783,2.63l.261.41v.68l-1.043.37A4.255,4.255,0,0,0,14,13.63l-2.557-1v-1.8A7.745,7.745,0,0,0,12.522,7V6A4.09,4.09,0,0,0,8.348,2,4.09,4.09,0,0,0,4.174,6V7a7.74,7.74,0,0,0,1.043,3.86V12.7l-2.557,1A4.011,4.011,0,0,0,0,17.35V20a1.022,1.022,0,0,0,1.043,1H22.957A1.022,1.022,0,0,0,24,20V17.47a3.508,3.508,0,0,0-2.358-3.27ZM16.7,8.75a1.044,1.044,0,0,1,2.087,0v1.44a2.9,2.9,0,0,1-.4,1.46l-.344.6a.263.263,0,0,1-.23.13h-.146a.263.263,0,0,1-.23-.13l-.344-.6a2.9,2.9,0,0,1-.4-1.46ZM6.261,5.85a2.045,2.045,0,0,1,2.087-2,2.045,2.045,0,0,1,2.087,2V7.28a5.8,5.8,0,0,1-.74,2.829l-.3.611a1.05,1.05,0,0,1-.918.53H8.264a1.052,1.052,0,0,1-.96-.53l-.344-.61a5.8,5.8,0,0,1-.7-2.83ZM14.609,19H2.087V17.25A2,2,0,0,1,3.4,15.39l3.9-1.47v-1a3.252,3.252,0,0,0,1.043.18h0a3.252,3.252,0,0,0,1.043-.18v1l3.882,1.42a2,2,0,0,1,1.336,1.87Zm7.3,0H16.7V17.35a3.874,3.874,0,0,0-.574-2l1.1-.4v-.88a2.173,2.173,0,0,0,.417.05h.209a2.173,2.173,0,0,0,.417-.05v.88l2.64,1a1.506,1.506,0,0,1,1.043,1.4V19Z"
        transform="translate(0 -2)"
        fill={props.fillColor}
      />
    </SvgIcon>
  );
}

export function ProjectIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="project" transform="translate(0.75 0.75)">
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="20.659"
          height="14.898"
          transform="translate(0 3.724)"
          stroke-width="1.5"
          stroke={props.fillColor}
          stroke-linecap="square"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          fill="none"
        />
        <rect
          id="Rectangle_11"
          data-name="Rectangle 11"
          width="7.594"
          height="3.724"
          transform="translate(6.532)"
          stroke-width="1.5"
          stroke={props.fillColor}
          stroke-linecap="square"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          fill="none"
        />
        <path
          id="Path_19"
          data-name="Path 19"
          d="M51.477,168.5,43,168.551V161.8H63.659v6.7H55.182"
          transform="translate(-43 -158.076)"
          fill="none"
          stroke={props.fillColor}
          stroke-linecap="square"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.5"
        />
        <rect
          id="Rectangle_12"
          data-name="Rectangle 12"
          width="3.71"
          height="3.54"
          transform="translate(8.477 8.754)"
          stroke-width="1.5"
          stroke={props.fillColor}
          stroke-linecap="square"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          fill="none"
        />
      </g>
    </SvgIcon>
  );
}

export function CaretDownIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        data-name="Path 298"
        d="M.049,17.221l3.993,5.006a.18.18,0,0,0,.272,0l3.994-5.007a.248.248,0,0,0,.036-.227.233.233,0,0,0-.016-.038.174.174,0,0,0-.153-.092H.182a.174.174,0,0,0-.153.092.228.228,0,0,0-.016.037A.249.249,0,0,0,.049,17.221Z"
        fill={props.fillColor}
      />
    </SvgIcon>
  );
}

export function AlertCircleIcon(props) {
  return (
    <SvgIcon {...props}>
      <circle
        id="Ellipse_237"
        data-name="Ellipse 237"
        cx="5.5"
        cy="5.5"
        r="5.5"
        fill={props.fillColor}
      />
    </SvgIcon>
  );
}

export function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        id="home"
        d="M22,8.808,12.85,2.274a1.435,1.435,0,0,0-1.69,0L2,8.808l1.06,1.636L4,9.779V19.944A1.029,1.029,0,0,0,5,21h5V15.722h4V21h5a1.029,1.029,0,0,0,1-1.056V9.779l.94.665Z"
        transform="translate(-2 -1.999)"
        fill={props.fillColor}
        stroke={props.fillColor}
        stroke-width="1"
      />
    </SvgIcon>
  );
}

export function CommunityIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        id="community"
        d="M21.976,12.455V11.8a2.737,2.737,0,0,0,1.352-2.341V8.3a2.716,2.716,0,0,0-5.431,0V9.462a2.643,2.643,0,0,0,1.3,2.293v.7a4.308,4.308,0,0,0-1.038.434,3.479,3.479,0,0,0-3.186-2.1,3.436,3.436,0,0,0-3.138,2.028,5.028,5.028,0,0,0-.941-.362V11.8a2.737,2.737,0,0,0,1.352-2.341V8.3a2.7,2.7,0,0,0-5.407,0V9.462a2.643,2.643,0,0,0,1.3,2.293v.7A5.019,5.019,0,0,0,4.5,17.307a.371.371,0,0,0,.362.362h7.217A3.55,3.55,0,0,0,13.262,18.8V19.89a6.521,6.521,0,0,0-4.828,6.348A.371.371,0,0,0,8.8,26.6H21.228a.371.371,0,0,0,.362-.362,6.613,6.613,0,0,0-4.9-6.348V18.852a3.541,3.541,0,0,0,1.231-1.159h7.41a.371.371,0,0,0,.362-.362A5.149,5.149,0,0,0,21.976,12.455ZM5.224,16.969a4.309,4.309,0,0,1,3.355-3.862.384.384,0,0,0,.29-.362V11.538a.323.323,0,0,0-.193-.314,1.977,1.977,0,0,1-1.11-1.762V8.3a1.979,1.979,0,0,1,3.959,0V9.462a2.036,2.036,0,0,1-1.134,1.786.379.379,0,0,0-.217.338v1.159a.384.384,0,0,0,.29.362,4.774,4.774,0,0,1,1.159.434,3.748,3.748,0,0,0-.072.748v1.545a3.611,3.611,0,0,0,.193,1.134ZM16.159,18.3a.379.379,0,0,0-.217.338v1.545a.384.384,0,0,0,.29.362,5.9,5.9,0,0,1,4.61,5.359H9.159A5.8,5.8,0,0,1,13.7,20.566a.384.384,0,0,0,.29-.362V18.61a.323.323,0,0,0-.193-.314,2.7,2.7,0,0,1-1.521-2.438V14.314a2.728,2.728,0,1,1,5.455,0v1.521A2.735,2.735,0,0,1,16.159,18.3Zm2.1-1.328a3.611,3.611,0,0,0,.193-1.134V14.29a2.838,2.838,0,0,0-.072-.652,4.037,4.037,0,0,1,1.255-.507.384.384,0,0,0,.29-.362V11.562a.323.323,0,0,0-.193-.314,1.977,1.977,0,0,1-1.11-1.762V8.328a1.979,1.979,0,1,1,3.959,0V9.486a2.036,2.036,0,0,1-1.134,1.786.379.379,0,0,0-.217.338v1.159a.384.384,0,0,0,.29.362,4.387,4.387,0,0,1,3.4,3.862l-6.662-.024Z"
        transform="translate(-4 -5.1)"
        fill={props.fillColor}
        stroke={props.fillColor}
        stroke-width="1"
      />
    </SvgIcon>
  );
}

export function CalendarIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="layer1" transform="translate(0.65 0.666)">
        <g id="g4516">
          <rect
            id="rect51"
            width="19.461"
            height="19.973"
            stroke-width="1.3"
            transform="translate(0 1.5)"
            stroke={props.fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
          />
          <line
            id="line53"
            x2="19.461"
            transform="translate(0 5.743)"
            fill="none"
            stroke={props.fillColor}
            stroke-width="1.3"
          />
          <rect
            id="rect55"
            width="3"
            height="2.817"
            stroke-width="1.3"
            transform="translate(3.447 8.011)"
            stroke={props.fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
          />
          <rect
            id="rect57"
            width="3"
            height="2.817"
            stroke-width="1.3"
            transform="translate(3.447 14.01)"
            stroke={props.fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
          />
          <rect
            id="rect59"
            width="3"
            height="2.817"
            stroke-width="1.3"
            transform="translate(8.231 8.011)"
            stroke={props.fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
          />
          <rect
            id="rect61"
            width="3"
            height="2.817"
            stroke-width="1.3"
            transform="translate(8.231 14.01)"
            stroke={props.fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
          />
          <rect
            id="rect63"
            width="3"
            height="2.817"
            stroke-width="1.3"
            transform="translate(13.014 8.011)"
            stroke={props.fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
          />
          <rect
            id="rect65"
            width="3"
            height="2.817"
            stroke-width="1.3"
            transform="translate(13.014 14.01)"
            stroke={props.fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
          />
          <line
            id="line67"
            x2="15.583"
            transform="translate(1.939 19.205)"
            fill="none"
            stroke={props.fillColor}
            stroke-width="1.3"
          />
          <line
            id="line69"
            x1="0.037"
            y1="1.5"
            stroke-width="1.3"
            transform="translate(5.113)"
            stroke={props.fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
          />
          <line
            id="line71"
            y1="1.5"
            stroke-width="1.3"
            transform="translate(15.291)"
            stroke={props.fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function FeedIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 36.029 29.475">
      <path
        id="Icon_metro-feed"
        data-name="Icon metro-feed"
        d="M14.139,17.352a3.856,3.856,0,1,1,3.856,3.856A3.856,3.856,0,0,1,14.139,17.352Zm8.446-8.48a9.644,9.644,0,0,1,0,16.959,16.245,16.245,0,0,0,2.159-8.48A16.245,16.245,0,0,0,22.585,8.873Zm-11.338,8.48a16.245,16.245,0,0,0,2.159,8.48,9.644,9.644,0,0,1,0-16.959,16.245,16.245,0,0,0-2.159,8.48Zm-5.784,0A20.7,20.7,0,0,0,9.7,30.358a15.428,15.428,0,0,1,0-26.012A20.7,20.7,0,0,0,5.463,17.352ZM26.29,4.347a15.428,15.428,0,0,1,0,26.012,20.7,20.7,0,0,0,4.237-13.006A20.7,20.7,0,0,0,26.29,4.347Z"
        transform="translate(-2.571 -4.346)"
        fill={props.fillColor}
      />
    </SvgIcon>
  );
}

export function ArrowIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 36.029 29.475">
      <g
        id="Icon_feather-arrow-up"
        data-name="Icon feather-arrow-up"
        transform="translate(1.414 1)"
      >
        <path
          id="Path_242"
          data-name="Path 242"
          d="M18,17.278V7.5"
          transform="translate(-13.292 -6.424)"
          fill="none"
          stroke={props.fillColor}
        />
        <path
          id="Path_243"
          data-name="Path 243"
          d="M7.5,12.066,12.066,7.5l4.566,4.566"
          transform="translate(-7.5 -7.5)"
          fill="none"
          stroke={props.fillColor}
        />
      </g>
    </SvgIcon>
  );
}

export function TodoIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 36.029 29.475">
      <g id="file" transform="translate(0.4 0.4)">
        <path
          id="Path_31"
          data-name="Path 31"
          d="M27.99,22H16.942a.508.508,0,0,0,0,1.007H27.99a.508.508,0,0,0,0-1.007Z"
          transform="translate(-11.466 -11.729)"
          fill={props.fillColor}
          stroke={props.fillColor}
          stroke-width="0.8"
        />
        <path
          id="Path_32"
          data-name="Path 32"
          d="M17,15.007h5.034a.5.5,0,0,0,0-1.007H17a.5.5,0,1,0,0,1.007Z"
          transform="translate(-11.94 -7.464)"
          fill={props.fillColor}
          stroke={props.fillColor}
          stroke-width="0.8"
        />
        <path
          id="Path_33"
          data-name="Path 33"
          d="M27.99,30H16.942a.508.508,0,0,0,0,1.007H27.99a.508.508,0,0,0,0-1.007Z"
          transform="translate(-11.466 -15.994)"
          fill={props.fillColor}
          stroke={props.fillColor}
          stroke-width="0.8"
        />
        <path
          id="Path_34"
          data-name="Path 34"
          d="M27.99,38H16.942a.508.508,0,0,0,0,1.007H27.99a.508.508,0,0,0,0-1.007Z"
          transform="translate(-11.466 -20.259)"
          fill={props.fillColor}
          stroke={props.fillColor}
          stroke-width="0.8"
        />
        <path
          id="Path_35"
          data-name="Path 35"
          d="M27.99,46H16.942a.508.508,0,0,0,0,1.007H27.99a.508.508,0,0,0,0-1.007Z"
          transform="translate(-11.466 -24.524)"
          fill={props.fillColor}
          stroke={props.fillColor}
          stroke-width="0.8"
        />
        <path
          id="Path_36"
          data-name="Path 36"
          d="M21.673,0H6.5V28.085h22V6.827Zm.274,1.6L26.9,6.553H21.947ZM7.436,27.149V.936H21.011V7.489h6.553v19.66Z"
          transform="translate(-6.5)"
          fill={props.fillColor}
          stroke={props.fillColor}
          stroke-width="0.8"
        />
      </g>
    </SvgIcon>
  );
}

export function ProjectOwnerIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 36.029 29.475">
      <g
        id="Group_1208"
        data-name="Group 1208"
        transform="translate(314.672 -180.773)"
      >
        <path
          id="Icon_ionic-md-person"
          data-name="Icon ionic-md-person"
          d="M11.4,11.4A3.448,3.448,0,1,0,7.948,7.948,3.458,3.458,0,0,0,11.4,11.4Zm0,1.724c-2.284,0-6.9,1.164-6.9,3.448v1.724H18.292V16.568C18.292,14.284,13.68,13.12,11.4,13.12Z"
          transform="translate(-315.452 181.137)"
          fill={props.fillColor}
        />
        <path
          id="Icon_feather-message-square"
          data-name="Icon feather-message-square"
          d="M4.5,22.817c0,1.686,1.693,3.053,3.781,3.053H30.967l7.562,6.106V7.553c0-1.686-1.693-3.053-3.781-3.053H8.281C6.193,4.5,4.5,5.867,4.5,7.553Z"
          transform="translate(-318.172 177.273)"
          fill="none"
          stroke={props.fillColor}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <g
          id="Icon_feather-file-text"
          data-name="Icon feather-file-text"
          transform="translate(-293.578 187.568)"
        >
          <path
            id="Path_285"
            data-name="Path 285"
            d="M19.3,19.5H12"
            transform="translate(-12 -15.85)"
            fill="none"
            stroke={props.fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <path
            id="Path_286"
            data-name="Path 286"
            d="M19.3,25.5H12"
            transform="translate(-12 -18.2)"
            fill="none"
            stroke={props.fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <path
            id="Path_287"
            data-name="Path 287"
            d="M13.825,13.5H12"
            transform="translate(-12 -13.5)"
            fill="none"
            stroke={props.fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
        </g>
        <g
          id="Icon_feather-file-text-2"
          data-name="Icon feather-file-text"
          transform="translate(-293.578 198.118)"
        >
          <path
            id="Path_285-2"
            data-name="Path 285"
            d="M19.3,19.5H12"
            transform="translate(-12 -19.5)"
            fill="none"
            stroke={props.fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <path
            id="Path_286-2"
            data-name="Path 286"
            d="M12,25.5"
            transform="translate(-12 -25)"
            fill="none"
            stroke={props.fillColor}
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
