import { useQuery } from "@apollo/client";
import { Pagination } from "@material-ui/lab";
import React, { useState } from "react";
import ErrorText from "../../components/Error";
import Loader from "../../components/FullScreenLoader";
import { GET_PUBLIC_USER } from "../../graphql/query";
import { Post } from "../PageHome/Feed";
import ListItem from "./ListItem";
import { useNavigate } from "react-router-dom";
import { Typography } from "@material-ui/core";

export default function COmmunityPeople() {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const { data, loading, error, refetch } = useQuery(GET_PUBLIC_USER, {
    variables: {
      offset,
      limit,
    },
  });

  if (loading) return <Loader />;
  if (error) return <ErrorText message={error?.message} />;

  return (
    <div>
      {data.getPublicUser.count == 0 ? (
        <Typography variant="h6">There are no public users</Typography>
      ) : (
        <div>
          {data.getPublicUser.user.map((item) => {
            return (
              <ListItem
                projectLink={"/public-profile/" + item.id}
                profilePic={item.profilePic}
                role={item.role.displayText}
                email={item.firstName + " " + item.lastName}
              />
            );
          })}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              onChange={(e, p) => {
                refetch({
                  variables: {
                    offset: (p - 1) * 10,
                    limit,
                  },
                });
                setOffset((p - 1) * 10);
                setCurrentPage(p);
              }}
              page={currentPage}
              style={{ marginTop: "2rem" }}
              color="primary"
              count={Math.ceil(data.getPublicUser.count / limit)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
