export const getUser = () => {
  const user = localStorage.getItem("user");
  if (user) {
    const parsed = JSON.parse(user);
    return parsed;
  }

  return {};
};

export const getRole = () => {
  const user = getUser();
  return user.role;
};

export const getUserInfoForHomeFeed = () => {
  const user = getUser();
  return {
    userid: user.id,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
  };
};

export const getProfilePic = () => {
  const user = getUser();
  if (user?.profilePic) return user.profilePic;
  if (user?.firstName)
    return `https://ui-avatars.com/api/?name=${user?.firstName}`;
  return "https://placehold.co/200";
};

export const getCoverUrl = ()=> {
   const user = getUser();
  if (user?.coverPic) return user.coverPic;
  return "https://placehold.co/600";
}

