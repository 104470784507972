import React from "react";

export default function MultiFileUpload({ files, setFiles }) {
  return (
    <input
      style={{
        borderRadius: "4px",
        border: "1px solid #ccc",
        width: "100%",
        padding: "1rem",
      }}
      multiple
      type="file"
      onChange={setFiles}
    />
  );
}
