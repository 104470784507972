import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { blueTextCOlor } from "../constant";

const styles = (theme) => ({
  userFields: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "0.3rem",
    marginTop: "0.3rem",
  },
  linkText: {
    fontSize: 16,
    color: blueTextCOlor,
    fontWeight: 400,
    // color: '#283e4a',
  },
  addIcon: {
    fontSize: "18px",
    marginTop: "5px",
    color: blueTextCOlor,
  },
});
function CardLinkList(props) {
  const { classes } = props;

  return (
    <div
      onClick={props.onClick}
      style={props.style}
      className={classes.userFields}
    >
      <Typography color="secondary" variant="subtitle1">
        <Link
          variant="inherit"
          color="inherit"
          href="#"
          className={classes.linkText}
        >
          {props.linkText}
        </Link>
      </Typography>
      <AddIcon
        color={blueTextCOlor}
        className={"fa fa-plus-circle " + classes.addIcon}
      ></AddIcon>
    </div>
  );
}

CardLinkList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(CardLinkList);
