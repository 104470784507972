import React from "react";
import { useApolloClient } from "@apollo/client";

export const useUpdateAndInsertCache = () => {
  const apolloClient = useApolloClient();

  const updateCache = (query, newData, queryKey, variables = {}) => {
    try {
      // Read the existing data from the cache
      const existingData = apolloClient.cache.readQuery({ query, variables });

      // Merge the new data with the existing data
      const updatedData = { [queryKey]: [newData,...existingData[queryKey]] };

      // Write the merged data back to the cache
      apolloClient.cache.writeQuery({
        query: query,
        variables: variables,
        data: updatedData,
      });

      console.log("Data stored in cache successfully");
    } catch (error) {
      console.error("Error storing data in cache:", error);
    }
  };
  return { updateCache };
  // Read the existing data from the cache
};
