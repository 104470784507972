import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import SampleProjects from "../../sample_projects";

import { useQuery } from "@apollo/client";
import { GET_NETWORK_MEMBERS } from "../../graphql/query";
import Loader from "../../components/FullScreenLoader";
import ErrorText from "../../components/Error";
import { black, darkBlack, darkGold, lightBlack } from "../../constant";
import ListItem from "../Components/list-item";
import { formatUnixTimeStampToFromNow } from "../../utils/datetime";
import { getUser } from "../../utils/user";

const styles = (theme) => ({
  appBar: {
    backgroundColor: lightBlack,
    height: "50px",
  },
  tabContainerInner: {
    // border: '1px solid black',
    background: lightBlack,
  },
  listContainer: {
    width: "100%",
  },
  appTool: {
    minHeight: "50px",
  },
  projectListContainer: {
    padding: "5px 15px",
    background: theme.palette.background.primary.main,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },
  listHolder: {
    padding: "15px",
  },
  headerContainer: {
    marginBottom: "15px",
    borderBottom: "1px solid #ccc",
  },
  titleName: {
    zIndex: -20,
    color: "#000",
    fontSize: "1.2rem",
  },
});

function getFields(type, item) {
  if (type == "homepage") {
    return {
      email: item.user.email,
      role: item.user.role?.displayText,
      status: item.status,
      profilePic: item.user?.profilePic,
    };
  }
  if (type == "project") {
    return {
      email: item.email,
      role: item.user_project_role.role,
      status: "",
    };
  }
}

function NetworkView({
  query,
  primaryDataObject,
  type,
  shouldRefetch,
  setShouldRefetch,
}) {
  var Projects = SampleProjects;
  const { data, loading, error, refetch } = useQuery(query, {
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (shouldRefetch) {
      setShouldRefetch(false);
      refetch();
    }
  }, [shouldRefetch]);

  if (loading) return <Loader />;

  if (error) return <ErrorText message={error.message} />;

  return (
    <>
      <NetWorkList
        networks={data.getNetworkMembers}
        type={type}
        title="Invitation"
      />
      <div style={{ marginBottom: "2rem" }}></div>
    </>
  );
}

export const NetWorkList = withStyles(styles)(function ({ classes, networks }) {
  const loggedInUser = getUser();
  return (
    <div>
      <Grid container spacing={2}>
        <Grid container>
          <div className={classes.listContainer}>
            {networks.length == 0 && (
              <Typography style={{ textAlign: "center" }} variant="body1">
                No Invitations found
              </Typography>
            )}
            {networks.map((item) => {
              const networkMember =
                item.sender.id == loggedInUser.id
                  ? item.recipient
                  : item.sender;
              const invitationType =
                item.sender.id == loggedInUser.id ? "Sent" : "Received";
              return (
                <ListItem
                  avatar={networkMember?.profilePic}
                  title={networkMember.email}
                  subtitle={formatUnixTimeStampToFromNow(item.createdAt)}
                  status={[invitationType, item.status]}
                />
              );
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
});

NetworkView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(NetworkView);
