import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import PageHome from "./routes/PageHome/PageHome";

import PageVersion from "./routes/PageVersion";
import PageLogin from "./routes/PageLogin";
import PageProfile from "./routes/PageProfile";
import PageSignUp from "./routes/PageSignUp";
import { Button, Typography, useTheme } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_LOGGED_IN_USER } from "./graphql/query";
import Layout from "./components/Layout";
import Loader from "./components/FullScreenLoader";
import MyProfile from "./routes/Profile/components/MyProfile";
import Community from "./routes/Community/Community";
import PublicProject from "./routes/PublicProject/PublicProject";
import EventCalendar from "./routes/PublicProject/EventCalendar";
import PageProjects from "./routes/PageProject/PageProjects";
import PublicProfileContainer from "./routes/Profile/PublicProfile";

function App() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  var theme = useTheme();
  document.body.style.backgroundColor = theme.palette.background.primary.main; // Set the style

  const [
    getLoggedInUserInfo,
    { loading: loggedInLoading, error: loggedInError },
  ] = useLazyQuery(GET_LOGGED_IN_USER);

  useEffect(() => {
    handlePageLoad();
  }, []);

  const handlePageLoad = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const jwt = urlParams.get("jwt");
      if (!jwt && !localStorage.getItem("token")) {
        setLoading(false);
        setError(
          "Invalid token or token has expired, redirecting to login in 10 seconds"
        );
        return;
      }

      const { data } = await getLoggedInUserInfo({
        variables: { jwt: jwt || localStorage.getItem("token") },
      });
      localStorage.setItem("user", JSON.stringify(data.loggedInUserViaJwt));
      if (jwt) localStorage.setItem("token", jwt);
      if (urlParams.get("eventId")) {
        localStorage.setItem("eventId", urlParams.get("eventId"));
      }
      if (urlParams.get("projectEventId")) {
        localStorage.setItem("projectEventId", urlParams.get("projectEventId"));
      }

      // localStorage.setItem("token", jwt);

      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  if (loading || loggedInLoading)
    return (
      <Layout>
        <div style={{ marginTop: "5rem" }}>
          <Loader />
        </div>
      </Layout>
    );

  if (error || loggedInError) {
    return (
      <Layout>
        <div
          style={{
            marginTop: "5rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            Your have been either been logged out or the token is invalid,
            please click on the link below to reauthenticate{" "}
          </Typography>
          <Button
            onClick={() =>
              window.open(
                process.env.REACT_APP_STATIC_SITE_URL + "sign-in",
                "_self"
              )
            }
            variant="contained"
            style={{ marginTop: "1rem" }}
            color="primary"
          >
            Re-Authenticate
          </Button>
        </div>
      </Layout>
    );
  }

  return (
    <Routes>
      <Route path="/" exact element={<PageHome />} />

      <Route
        path="/project/:projectId/v/:versionId/"
        element={<PageVersion />}
      />
      <Route path="/login" element={<PageLogin />} />
      <Route path="/sign-up" element={<PageSignUp />} />
      <Route path="/profile" element={<MyProfile />} />
      <Route path="/community" element={<Community />} />
      <Route path="/public-project" element={<PublicProject />} />
      <Route path="/event-calendar" element={<EventCalendar />} />
      <Route path="/project/:projectId/" exact element={<PageProjects />} />
      <Route path="public-profile/:id" element={<PublicProfileContainer />} />
    </Routes>
  );
  // }
}

export default App;

/*


*/
