import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { AspectRatio, EditOutlined } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import Layout from "../../../components/Layout";
import Loader from "../../../components/FullScreenLoader";
import ErrorText from "../../../components/Error";
import { GET_PUBLIC_USER_BY_iD } from "../../../graphql/query";
import { useParams } from "react-router-dom";
import { ADD_TO_NETWORK, UPDATE_USER } from "../../../graphql/mutation";
import { NotificationManager } from "react-notifications";

export default function PublicProfile() {
  const params = useParams();
  const { data, loading, error, refetch } = useQuery(GET_PUBLIC_USER_BY_iD, {
    variables: { userId: Number(params.id) },
  });

  const [
    addToNetwork,
    { data: addData, error: addError, loading: addLoading },
  ] = useMutation(ADD_TO_NETWORK);

  const onAddClick = async () => {
    try {
      if (isInNetwork) {
        NotificationManager.info(
          "Cannot add to your network, either you have already sent invitation or already in your network"
        );
        return;
      }
      await addToNetwork({
        variables: { user_id: Number(params.id) },
      });
      NotificationManager.success("Invitation sent");
      refetch();
    } catch {}
  };

  const getDivStyle = (profilePic) => {
    const backgroundImage = profilePic
      ? `"${profilePic}"`
      : "https://placehold.co/200x200";

    return {
      height: "10rem",
      borderRadius: "50%",
      backgroundImage: `url(${backgroundImage})`,
    };
  };

  if (loading)
    return (
      <Layout>
        <Box
          style={{
            maxWidth: "800px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Card style={{ background: "aliceblue" }}>
            <Loader />
          </Card>
        </Box>
      </Layout>
    );

  if (error)
    return (
      <Layout>
        <Box
          style={{
            maxWidth: "800px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Card style={{ background: "aliceblue" }}>
            <ErrorText message={error?.message} />
          </Card>
        </Box>
      </Layout>
    );
  const {
    getPublicUserProfile: { user, isInNetwork, invitationStatus },
  } = data;
  return (
    <Layout>
      <Box
        style={{
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {addError && <ErrorText message={addError?.message} />}

        <Card style={{ background: "aliceblue" }}>
          <CardHeader
            titleTypographyProps={{ fontSize: "1.3rem" }}
            style={{ borderBottom: "1px solid #dddfe2" }}
            title={user.firstName + " " + user.lastName}
            subheader=""
          ></CardHeader>

          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <div
                  style={{
                    position: "relative",
                    borderRadius: "inherit",

                    paddingBottom:
                      "calc(var(--AspectRatio-paddingBottom) - 2 * var(--variant-borderWidth, 0px))",
                    overflow: "hidden",
                    transition: "inherit",
                    fontFamily:
                      'var(--joy-fontFamily-body, "Inter", var(--joy-fontFamily-fallback, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"))',
                    fontSize: "var(--joy-fontSize-md, 1rem)",
                    lineHeight: "var(--joy-lineHeight-md, 1.5)",
                    color:
                      "var(--variant-softColor, var(--joy-palette-neutral-softColor, var(--joy-palette-neutral-700, #32383E)))",
                    "-variantBorderWidth": "0px",
                  }}
                >
                  <div style={getDivStyle(user.profilePic)}></div>
                </div>
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" component="h6">
                      Email
                    </Typography>

                    <Typography variant="subtitle2">{user.email}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" component="h6">
                      Role
                    </Typography>
                    <Typography>{user.role.displayText}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" component="h6">
                      First Name
                    </Typography>
                    <Typography>{user.firstName}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" component="h6">
                      Last Name
                    </Typography>
                    <Typography>{user.lastName}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" component="h6">
                      Phone Number
                    </Typography>
                    <Typography>{user.phoneNumber}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6" component="h6">
                      Institution
                    </Typography>
                    <Typography>
                      {user?.institution?.name || "No linked to institution"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography variant="h6" component="h6">
                      Description
                    </Typography>
                    <Typography>{user.description}</Typography>
                  </Grid>
                </Grid>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={onAddClick}
                    autoFocus
                    variant="contained"
                    color="primary"
                    disabled={isInNetwork}
                  >
                    {isInNetwork
                      ? `In your network (${invitationStatus})`
                      : "Add To Network"}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Layout>
  );
}
