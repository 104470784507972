import axios from "axios";
export const uploadFile = async (file) => {
  try {
    let formData = new FormData();
    formData.append("file", file);
    const { data } = await axios.post(
      process.env.REACT_APP_FILE_UPLOAD_URL,
      formData
    );
  
    return data.fileUrl
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};
