import { useMutation, useQuery } from "@apollo/client";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";

import CustomizedDialogs from "../../components/Dialog";
import ErrorText from "../../components/Error";
import Loader from "../../components/FullScreenLoader";
import { ADD_PROJECT_MEMBER, ADD_TO_NETWORK } from "../../graphql/mutation";
import {
  GET_NETWORK_MEMBERS,
  GET_NETWORK_MEMBERS_TO_ADD_TO_PROJECT,
} from "../../graphql/query";
import { useParams } from "react-router-dom";
import { getUser } from "../../utils/user";
import { NotificationManager } from "react-notifications";

export default function ProjectInvitationModel({
  teamMemberRefetch,
  setTeamMemberRefetch,
  setShowInvitationModel,
  projectId,
}) {
  const [selectedUser, setSelectedUser] = useState("");
  const [role, setRole] = useState("");

  const [validationError, setValidationError] = useState("");

  const { data, loading, error, refetch } = useQuery(
    GET_NETWORK_MEMBERS_TO_ADD_TO_PROJECT,
    {
      variables: {
        projectId: parseInt(projectId),
      },
      fetchPolicy: "network-only",
    }
  );

  const [
    addProjectMember,
    {
      data: addProjectData,
      loading: addProjectLoading,
      error: addProjectError,
    },
  ] = useMutation(ADD_PROJECT_MEMBER);

  const onAddClick = async () => {
    try {
      if (!selectedUser) {
        setValidationError("Please select a user");
        return;
      }
      if (!role) {
        setValidationError("Please select a role");
        return;
      }
      setValidationError(false);
      await addProjectMember({
        variables: {
          userId: parseInt(selectedUser),
          role,
          projectId: parseInt(projectId),
        },
      });
      // await addToNetwork({ variables: { email } });
      setSelectedUser("");
      setRole("");
      setTeamMemberRefetch(true);
      refetch();
      NotificationManager.success("Sent an invitation to the user");
      //setShowInvitationModel(false);
    } catch (error) {
      console.log("eee", error);
    }
  };
  const user = getUser();

  const hasEligibleNetworkMemberToAdd = data
    ? data.getNetworkMemberstoAddToProject.filter(
        (item) => item.userId != user.id
      ).length > 0
    : false;

  return (
    <CustomizedDialogs
      loading={addProjectLoading}
      error={error?.message || validationError || addProjectError?.message}
      open={true}
      success={addProjectData && "User has been invited successfully"}
      onClose={setShowInvitationModel}
      handleSaveClick={onAddClick}
      title="Invite to Project"
      content={
        <>
          <Typography
            variant="subtitle1"
            style={{ marginBottom: "1rem" }}
            gutterBottom
          >
            Select the email of the person and role you wish to Invite
          </Typography>

          {loading ? (
            <Loader />
          ) : hasEligibleNetworkMemberToAdd ? (
            <>
              <FormControl fullWidth style={{ marginBottom: "1rem" }}>
                <InputLabel variant="outlined" id="project-select-label">
                  Email
                </InputLabel>
                <Select
                  variant="outlined"
                  labelId="project-select-label"
                  id="project-select"
                  label="Project"
                  onChange={(e) => setSelectedUser(e.target.value)}
                  value={selectedUser}
                >
                  {data.getNetworkMemberstoAddToProject.map((item) => (
                    <MenuItem value={item.userId}>{item.email}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth style={{ marginBottom: "1rem" }}>
                <InputLabel variant="outlined" id="project-select-label">
                  Role
                </InputLabel>
                <Select
                  variant="outlined"
                  labelId="project-select-label"
                  label="Project"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <MenuItem value={"administrator"}>Administrator</MenuItem>
                  <MenuItem value={"ngo"}>NGO</MenuItem>
                  <MenuItem value={"faculty"}>Faculty</MenuItem>
                  <MenuItem value={"student"}>Student</MenuItem>
                </Select>
              </FormControl>
            </>
          ) : (
            <ErrorText
              message={
                "You don't have any member in your network. Please add people to your network. Only people in your network cna be added to your project"
              }
            />
          )}
        </>
      }
    />
  );
}
