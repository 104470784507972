import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import StarIcon from "@material-ui/icons/Star";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link } from "react-router-dom";
import { Chip } from "@material-ui/core";

const styles = (theme) => ({
  projectListContainer: {
    padding: "5px 15px",
    background: theme.palette.background.primary.main,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
    padding: "1rem",
  },
  listHolder: {
    padding: "15px",
  },
  avatar: {
    display: "inline-block",
    height: 35,
    width: 35,
    // border: `5px solid ${theme.palette.common.white}`,
    background: theme.palette.grey[200],
    zIndex: 999,
  },
  avatarSec: {
    display: "inline-block",
    height: 35,
    width: 35,
    background: theme.palette.grey[200],
    position: "relative",
    left: "-10px",
  },
  headerContainer: {
    marginBottom: "15px",
    borderBottom: "1px solid #ccc",
  },
});

function ListItem(props) {
  const { classes, email, role } = props;

  return (
    // <div>
    <Grid container className={classes.projectListContainer}>
      <div>
        <Link  to={props.projectLink}>
          <div
            style={{ display: "flex", alignItems: role ? "null" : "center" }}
          >
            <Avatar
              src={props?.profilePic}
              style={{ marginRight: "0.5rem" }}
            ></Avatar>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body1" color="primary">
                {email}
              </Typography>
              <Typography variant="body2" color="secondary" gutterBottom>
                {role}
              </Typography>
            </div>
          </div>
        </Link>
      </div>

      {/*  <Typography variant="body2">
        <Chip
          size="small"
          label={
            status == "pending"
              ? "Pending"
              : status == "accepted"
              ? "Accepted"
              : "Declined"
          }
          color={
            status == "pending"
              ? "grey"
              : status == "accepted"
              ? "primary"
              : "secondary"
          }
        />
      </Typography> */}
    </Grid>
    // </div>
  );
}

ListItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(ListItem);
