import { Button, Paper } from "@material-ui/core";
import React from "react";
import Carousel from "react-material-ui-carousel";
import ModelDetailCard from "./ModalDetailCard";

export function EventAnnouncementDetail({ data }) {
  return <ModelDetailCard data={data} />;
}

function Item(props) {
  return <Paper></Paper>;
}
