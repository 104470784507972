import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { useTheme, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import Popover from "@material-ui/core/Popover";
import Toolbar from "@material-ui/core/Toolbar";
import AppBarBase from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import NotificationIcon from "@material-ui/icons/Notifications";
import Home from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AppsIcon from "@material-ui/icons/Apps";
import GridOnIcon from "@material-ui/icons/GridOn";
import AcUnitRoundedIcon from "@material-ui/icons/AcUnitRounded";
import AppBarCollapse from "./AppBarCollapse";

import InputSearch from "./InputSearch";
import ActivityListItem from "./ActivityListItem";

import { getProfilePic } from "../utils/user";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  navigation: {},
  appBar: {
    background: theme.palette.primary.background,
  },
  toolbar: {
    width: "100%",
    maxWidth: theme.layout.contentMaxWidth,
    margin: "0 auto",
  },
  flex: {
    flexGrow: 1,
  },
  search: {
    marginLeft: theme.spacing.unit * 4,
    background: theme.palette.grey[200],
    width: 250,
  },

  headerIcon: {
    height: 40,
    width: 30,
  },
  IconLabel: {
    position: "absolute",
    bottom: "0",
    color: "#f5f5f5",
    fontSize: "14px",
    fontWeight: "400",
  },
  buttonColor: {
    color: "#ffffff",
    fontSize: "30px",
  },
});

const AppBar = ({ classes }) => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const notificationButton = useRef();
  const theme = useTheme();
  // const theme = useTheme();
  const navigate = useNavigate();
  const handleToggleNotification = () => {
    navigate("/");
  };

  return (
    <AppBarBase className={classes.appBar} position="fixed">
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit">
          <IconButton
            className={classes.icon}
            onClick={handleToggleNotification}
            buttonRef={notificationButton}
          >
            {/* <AcUnitRoundedIcon className={classes.buttonColor} /> */}
            <img src="\assets\images\crc-logo@2x.png" alt="icon"></img>
          </IconButton>
        </Typography>

        <div className={classes.flex} />
        <AppBarCollapse />

        <div style={{ cursor: "pointer" }} onClick={() => navigate("/profile")}>
          <Avatar
            style={{ background: theme.palette.primary.gold }}
            src={getProfilePic()}
          />
        </div>
      </Toolbar>
    </AppBarBase>
  );
};

AppBar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
};

export default withStyles(styles)(AppBar);
