import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ProjectListItems from "../../components/ProjectListItems";
import SampleProjects from "../../sample_projects";

import { useQuery } from "@apollo/client";
import { GET_NETWORK_MEMBERS } from "../../graphql/query";
import Loader from "../../components/FullScreenLoader";
import ErrorText from "../../components/Error";
import NetworkListItem from "../../routes/EventModal/network-list-item";

const styles = (theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.secondary.main,
    height: "50px",
  },
  tabContainerInner: {
    // border: '1px solid black',
    background: theme.palette.background.secondary.main,
  },
  listContainer: {
    width: "100%",
  },
  appTool: {
    minHeight: "50px",
  },
  projectListContainer: {
    padding: "5px 15px",
    background: theme.palette.background.primary.main,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },
  listHolder: {
    padding: "15px",
  },
  headerContainer: {
    marginBottom: "15px",
    borderBottom: "1px solid #ccc",
  },
  titleName: {
    zIndex: -20,
  },
});

function getFields(type, item) {
  if (type == "homepage") {
    return {
      email: item.user.email,
      role: item.user.role?.displayText,
      status: item.status,
      profilePic: item.user?.profilePic,
    };
  }
  if (type == "project") {
    return {
      email: item.email,
      role: item.user_project_role.role,
      status: "",
    };
  }
}

function NetworkView({
  query,
  primaryDataObject,
  type,
  shouldRefetch,
  setShouldRefetch,
}) {
  var Projects = SampleProjects;
  const { data, loading, error, refetch } = useQuery(query);
  useEffect(() => {
    if (shouldRefetch) {
      setShouldRefetch(false);
      refetch();
    }
  }, [shouldRefetch]);

  if (loading) return <Loader />;

  if (error) return <ErrorText message={error.message} />;

  return (
    <>
      <NetWorkList
        networks={data.getNetworkMembers}
        type={type}
        title=" Sent Invitation"
      />
      <div style={{ marginBottom: "2rem" }}></div>
    </>
  );
}

export const NetWorkList = withStyles(styles)(function ({
  classes,
  networks,
  type,
  title,
}) {
  return (
    <div>
      <Grid className={classes.tabContainerInner} container spacing={2}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.appTool}>
            <Typography
              color="primary"
              variant="subtitle1"
              className={classes.titleName}
            >
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.listHolder}>
          <div className={classes.listContainer}>
            {networks.length == 0 && (
              <Typography style={{ textAlign: "center" }} variant="body1">
                No Invitations found
              </Typography>
            )}
            {networks.map((item) => {
              const fields = getFields(type, item);
              return (
                <NetworkListItem
                  email={fields.email}
                  role={fields.role}
                  status={fields.status}
                  profilePic={fields?.profilePic}
                />
              );
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
});

NetworkView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(NetworkView);
