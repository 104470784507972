import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import CustomCard from "../../components/CustomCard";
import { ThemeCardsProvider } from "../../components/ThemeCards";
import { GET_TOP_FIVE_INVITATION } from "../../graphql/query";
import Loader from "../../components/FullScreenLoader";
import ErrorText from "../../components/Error";
import { capitalizeFirstLetter } from "../../utils/stringUtil";
import AlertCardList from "../../components/AlertCardList";
import { Typography } from "@material-ui/core";

export const InvitationSideBar = () => {
  const { data, loading, error } = useQuery(GET_TOP_FIVE_INVITATION);

  if (loading) return <Loader width={20} />;

  if (error) return <ErrorText message={error?.message} />;

  return (
    <ThemeCardsProvider>
      <CustomCard
        cardTitle="Top 5 Invitation Received"
        customStyle={{ marginTop: "10px" }}
      >
        {data.getTopFiveInvitation.length == 0 && (
          <Typography
            variant="body2"
            style={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#495057",
              borderTop: "2px solid #efefef",
              padding: "10px 0 0 0",
              margin: "10px 0 0 0",
            }}
          >
            No Pending Invitation
          </Typography>
        )}
        {data.getTopFiveInvitation.map((item) => {
          return (
            <AlertCardList
              title={item.name}
              text={`$[item.fromFirstName} has invited you to ${capitalizeFirstLetter(
                item.entity_type
              )} - ${item.name}`}
            />
          );
        })}
      </CustomCard>
    </ThemeCardsProvider>
  );
};
