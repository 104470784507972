import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Layout from "../../components/Layout";

import Grid from "@material-ui/core/Grid";
import MainTabArea from "./MainTabArea";

import {
  StartPostIcon,
  ProjectOwnerIcon,
  TeamMessageIcon,
  FeedIcon,
  NotificationIcon,
  TodoIcon,
} from "../../components/CustomIcons";

import { ThemeCardsProvider } from "../../components/ThemeCards";

import { useQuery } from "@apollo/client";
import {
  GET_NETWORK_MEMBERS,
  GET_NEW_NOTIFICATION_COUNT,
} from "../../graphql/query";
import { showErrroDialog } from "../../components/ErrorDialog";
import NetworkView from "../../views/project-view/network-view";
import { Typography } from "@material-ui/core";
import { Post } from "../PageHome/Feed";


import COmmunityPeople from "./CommunityPeople";
import CommunityInstitutiion from "./CommunityInstotution";

const styles = (theme) => ({
  count: {
    marginRight: "15px",
  },
  root: {
    // minWidth: '700px',
    // background: theme.palette.background.secondary.main,
    minWidth: "100%",
  },
  summaryList: {
    display: "flex",
    justifyContent: "space-between",
  },
  Listtitle: {
    fontSize: "15px",
    fontWeight: "500px",
    color: "#0073b1",
    display: "flex",
    justifyContent: "center",
  },
  expandListItems: {
    borderTop: "2px solid #efefef",
    padding: "10px 0 0 0",
    margin: "10px 0 0 0",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  expandListTitle: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#283e4a",
  },
  newsCard: {
    marginTop: "10px",
  },
  newsTitle: {
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    fontWeight: "400",
    color: "#0073b1",
  },
  newsContent: {
    fontWeight: "400",
    color: "#707070",
    fontSize: "14px",
  },
  projectToolCard: {
    marginTop: "10px",
  },
  projectToolTitle: {
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    fontWeight: "400",
    color: "#0073b1",
  },
  projectToolContent: {
    fontWeight: "400",
    color: "#707070",
    fontSize: "14px",
  },
  aside: {
    width: "100%",
  },
  profile: {
    width: "100%",
  },
  rightSide: {
    width: "100%",
  },
  main: {
    width: "100%",
    flex: 1,
  },
});

const Community = ({ classes }) => {
  const [showCreatePostDialog, setShowCreatePostDialog] = useState(false);
  const [showInvitationModel, setShowInvitationModel] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [shouldRefetchInvitation, setShouldRefetchInvitation] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  var tabs = [
    {
      label: "People",
      icon: <StartPostIcon fontSize="large" />,
      panel: (
        <div>
          <COmmunityPeople />
        </div>
      ),
      onClick: () => setShowCreatePostDialog(true),
    },
    {
      label: "Institutions",
      icon: <StartPostIcon fontSize="large" />,
      panel: (
        <div>
          <CommunityInstitutiion />
        </div>
      ),
      onClick: () => setShowCreatePostDialog(true),
    },
  ];

  return (
    <Layout>
      <Grid container spacing={3} fluid>
        <Grid item xs={12} md={3}>
          <aside className={classes.profile}></aside>
        </Grid>

        <Grid item xs={12} md={6} style={{ background: "#ddd" }}>
          <MainTabArea
            setActiveTab={setActiveTab}
            tabs={tabs}
            activeTab={activeTab}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <aside className={classes.rightSide}>
            <div className={classes.fieldWorkSummary}>
              <ThemeCardsProvider></ThemeCardsProvider>
            </div>
          </aside>
        </Grid>
      </Grid>
    </Layout>
  );
};

Community.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(Community);
