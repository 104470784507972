import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  List,
  ListItemAvatar,
  Avatar,
  Icon,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  ListItem,
  Divider,
  Button,
  Popover,
  Typography,
} from "@material-ui/core";
import {
  AddAlert,
  AddAlertRounded,
  AddCircleOutlineRounded,
  AddIcCall,
  Assignment,
  AssignmentRounded,
  CheckBox,
  Close,
  HelpOutlineOutlined,
  HelpRounded,
  Inbox,
  NoteAddRounded,
  NoteRounded,
  NotificationsActive,
  QuestionAnswer,
  QuestionAnswerOutlined,
} from "@material-ui/icons";
import CustomizedDialogs from "../../../components/Dialog";
import ErrorText, { showErrorModal } from "../../../components/Error";
import ColorPicker from "../../../components/ColorPicker";
import DetailModal from "./DetailModal";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_CONTENT_ITEM,
  GET_CONTENT_ITEM_GROUP,
} from "../../../graphql/query";
import { SmallLoader } from "../../../components/FullScreenLoader";
import { darkBlack, gold, lightBlack } from "../../../constant";
import {
  CREATE_CONTENT_ITEM,
  CREATE_CONTENT_ITEM_GROUP,
} from "../../../graphql/mutation";
import { MdQuestionMark, MdTask } from "react-icons/md";
import { NotificationManager } from "react-notifications";

const useStyles = makeStyles({
  headerTitle: {
    fontSize: "1.2rem",
  },
});

const SwotAnalysis = ({ setShowLeftAndRightBar }) => {
  const { projectId } = useParams();
  const [index, setIndex] = useState(0);
  const [dataModal, setDataModal] = useState([]);
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);

  const [title, setTitle] = useState("");
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [detailModalData, setDetailModalData] = useState({});

  const [groupColor, setGroupColor] = useState("#611");

  const [description, setDescription] = useState("");

  const [checkBox1, setCheckBox1] = useState(null);
  const [checkBox2, setCheckBox2] = useState(null);
  const groupRefetch = useRef(null);

  useEffect(() => {
    setShowLeftAndRightBar(false);
    return () => setShowLeftAndRightBar(true);
  }, []);

  const [addGroup, { loading: addGroupLoading, error: addGroupError }] =
    useMutation(CREATE_CONTENT_ITEM_GROUP);
  const handleCheckboxChange = (id) => {
    if (checkBox1 == id) {
      setCheckBox1(null);
      return;
    }
    if (checkBox2 == id) {
      setCheckBox2(null);
      return;
    }
    if (checkBox1 && checkBox2) {
      NotificationManager.error("You can only select 2 checkbox at a time");
      return;
    }
    if (!checkBox1) {
      setCheckBox1(id);
    } else {
      setCheckBox2(id);
    }
  };

  const createGroup = () => {
    if (!checkBox1 || !checkBox2) {
      NotificationManager.error("Please select two items to create a group");
      return;
    }
    setShowAddGroupModal(true);
  };

  const saveGroup = async () => {
    try {
      if (!title) {
        NotificationManager.error("Title is required");
        return;
      }
      if (!description) {
        NotificationManager.error("Description is required");
        return;
      }
      await addGroup({
        variables: {
          name: title,
          description: description,
          projectId: Number(projectId),
          color: groupColor,
          ct1: Number(checkBox1),
          ct2: Number(checkBox2),
        },
      });
      setShowAddGroupModal(false);
      setTitle("");
      setDescription("");
      setCheckBox1(null);
      setCheckBox2(null);
      groupRefetch.current();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleClick = (item) => {
    setShowDetailModal(true);
    setDetailModalData({ ...item });
  };

  const containerStyle = {};

  return (
    <div>
      {showDetailModal && (
        <DetailModal
          item={detailModalData}
          data={dataModal}
          onClose={() => setShowDetailModal(false)}
        />
      )}
      {showAddGroupModal && (
        <CustomizedDialogs
          loading={addGroupLoading}
          error={addGroupError}
          open={showAddGroupModal}
          handleSaveClick={saveGroup}
          title="Add Group"
          content={
            <>
              <TextField
                style={{ marginBottom: "1rem" }}
                fullWidth
                id="outlined-basic"
                label="Title"
                type="text"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />

              <TextField
                style={{ marginBottom: "1rem" }}
                fullWidth
                id="outlined-basic"
                label="Description"
                type="text"
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <ColorPicker
                setColor={(color) => setGroupColor(color)}
                color={groupColor}
              />
            </>
          }
          onClose={() => setShowAddGroupModal(false)}
        />
      )}

      <Grid
        container
        spacing={1}
        justifyContent="flex-end"
        style={{ marginBottom: "1rem" }}
      >
        <Grid item>
          <Button
            variant="contained"
            onClick={createGroup}
            style={{ background: darkBlack, color: "gold" }}
          >
            Save Group
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid style={containerStyle} item xs={6}>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <ContentItemContainer
                onClick={handleClick}
                checkbox1={checkBox1}
                checkBox2={checkBox2}
                handleCheckboxChange={handleCheckboxChange}
                conatnerType="strength"
                height="30vh"
                containerTitle={"Strength"}
                data={dataModal.filter(
                  (item) => item.contentItemType == "strength"
                )}
              />
            </Grid>

            <Grid item md={6}>
              <ContentItemContainer
                onClick={handleClick}
                checkbox1={checkBox1}
                checkBox2={checkBox2}
                handleCheckboxChange={handleCheckboxChange}
                conatnerType="weakness"
                height="30vh"
                data={dataModal.filter(
                  (item) => item.contentItemType == "weakness"
                )}
                containerTitle="Weakness"
              />
            </Grid>

            <Grid item md={6}>
              <ContentItemContainer
                onClick={handleClick}
                checkbox1={checkBox1}
                checkBox2={checkBox2}
                handleCheckboxChange={handleCheckboxChange}
                height="30vh"
                data={dataModal.filter(
                  (item) => item.contentItemType == "oppurunity"
                )}
                conatnerType="oppurunity"
                containerTitle="Oppurtunity"
              />
            </Grid>

            <Grid item md={6}>
              <ContentItemContainer
                conatnerType="threat"
                onClick={handleClick}
                checkbox1={checkBox1}
                checkBox2={checkBox2}
                handleCheckboxChange={handleCheckboxChange}
                height="30vh"
                containerTitle="Threat"
                data={dataModal.filter(
                  (item) => item.contentItemType == "threat"
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid style={containerStyle} item xs={3}>
          <ContentItemContainer
            conatnerType="assumptions"
            checkbox1={checkBox1}
            checkBox2={checkBox2}
            onClick={handleClick}
            handleCheckboxChange={handleCheckboxChange}
            data={dataModal.filter(
              (item) => item.contentItemType == "assumptions"
            )}
            containerTitle="Assumptions"
          />
        </Grid>
        <Grid style={containerStyle} item xs={3}>
          <ContentItemContainer
            checkbox1={checkBox1}
            checkBox2={checkBox2}
            onClick={handleClick}
            groupRefetch={groupRefetch}
            handleCheckboxChange={handleCheckboxChange}
            data={dataModal.filter((item) => item.contentItemType == "groups")}
            conatnerType="groups"
            containerTitle="Groups"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const ContentItemContainer = ({
  height = "72vh",
  containerTitle,
  conatnerType,
  handleDataModalChange,
  handleCheckboxChange,
  checkbox1,
  checkBox2,
  onClick,
  groupRefetch,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("statement");
  const [description, setDescription] = useState("");
  const [rationale, setRationale] = useState("");

  const { projectId } = useParams();
  const isGroup = conatnerType == "groups";

  useEffect(() => {
    if (isGroup && refetch) {
      groupRefetch.current = refetch;
    }
  }, [refetch]);

  const { data, loading, error, refetch } = useQuery(
    isGroup ? GET_CONTENT_ITEM_GROUP : GET_CONTENT_ITEM,
    {
      variables: {
        projectId: Number(projectId),
        containerType: conatnerType,
      },
    }
  );

  const [addContentItem, { loading: addLoading, error: addError }] =
    useMutation(CREATE_CONTENT_ITEM);
  const materializeUIClasses = useStyles();

  const contentItem = data
    ? data[isGroup ? "contentItemGroup" : "contentItem"]
    : [];
  return (
    <>
      {showModal && (
        <CustomizedDialogs
          open={showModal}
          loading={addLoading}
          error={addError}
          handleSaveClick={async () => {
            try {
              if (!title) {
                NotificationManager.error("Title is required");
                return;
              }
              if (!description) {
                NotificationManager.error("Description is required");
                return;
              }

              await addContentItem({
                variables: {
                  name: title,
                  description,
                  rationale,
                  type,
                  projectId: Number(projectId),
                  containerType: conatnerType,
                },
              });
            } catch (err) {
              console.log("Err", err);
            }
            refetch();
            setTitle("");
            setRationale("");
            setDescription("");
            setType("statement");
            setShowModal(false);
          }}
          title="Add Item"
          content={
            <>
              <TextField
                style={{ marginBottom: "1rem" }}
                fullWidth
                id="outlined-basic"
                label="Title"
                type="text"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />

              <TextField
                style={{ marginBottom: "1rem" }}
                fullWidth
                id="outlined-basic"
                label="Description"
                type="text"
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />

              <TextField
                style={{ marginBottom: "1rem" }}
                fullWidth
                id="outlined-basic"
                label="Rationale"
                type="text"
                variant="outlined"
                value={rationale}
                onChange={(e) => setRationale(e.target.value)}
              />

              <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <FormControlLabel
                    value="statement"
                    control={<Radio />}
                    label="Is Statement"
                  />
                  <FormControlLabel
                    value="reminder"
                    control={<Radio />}
                    label="Is Reminder"
                  />
                  <FormControlLabel
                    value="question"
                    control={<Radio />}
                    label="Is Question"
                  />
                  <FormControlLabel
                    value="task"
                    control={<Radio />}
                    label="Is Task"
                  />
                </RadioGroup>
              </FormControl>
            </>
          }
          onClose={() => setShowModal(false)}
        />
      )}
      <Card>
        <CardHeader
          style={{
            borderBottom: "1px solid #ccc",
            background: lightBlack,
            alignItems: "center",
          }}
          classes={{
            title: materializeUIClasses.headerTitle,
          }}
          title={containerTitle}
          action={
            <AddCircleOutlineRounded
              style={{
                cursor: "pointer",
                background: darkBlack,
                color: gold,
                borderRadius: "50%",
                marginTop: "0.5rem",
              }}
              onClick={() => setShowModal(true)}
            />
          }
        ></CardHeader>
        <CardContent>
          <div style={{ height: height, overflowY: "scroll" }}>
            <List dense>
              {loading ? (
                <ListItem button>
                  <SmallLoader />
                </ListItem>
              ) : error ? (
                <ListItem>
                  <ErrorText message={error?.message} />
                </ListItem>
              ) : contentItem.length == 0 ? (
                <ListItem button>
                  <ListItemText primary={"No Items has been added"} />
                </ListItem>
              ) : (
                contentItem.map((item) => {
                  return (
                    <>
                      <ListItem button onClick={() => onClick(item)}>
                        <ListItemAvatar>
                          {item.color ? (
                            <div
                              style={{
                                background: item.color,
                                width: "1rem",
                                height: "1rem",
                                borderRadius: "50%",
                              }}
                            ></div>
                          ) : (
                            <>
                              {item.type == "question" ? (
                                <MdQuestionMark
                                  style={{ fontSize: "1.5rem" }}
                                />
                              ) : item.type == "statement" ? (
                                <QuestionAnswerOutlined />
                              ) : item.type == "task" ? (
                                <MdTask style={{ fontSize: "1.5rem" }} />
                              ) : (
                                <NotificationsActive />
                              )}
                            </>
                          )}
                        </ListItemAvatar>

                        <ListItemText
                          secondary={
                            item.user.firstName + " " + item.user.lastName
                          }
                          primary={item.name}
                        />
                        {item.type && (
                          <ListItemSecondaryAction>
                            <input
                              style={{ padding: "1rem" }}
                              checked={
                                checkbox1 == item.id || checkBox2 == item.id
                              }
                              onChange={() => handleCheckboxChange(item.id)}
                              type="checkbox"
                            />
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                      <Divider />
                    </>
                  );
                })
              )}
            </List>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default SwotAnalysis;

const PopoverComponent = ({ component, message }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div onMouseEnter={handleClick}> {component}</div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          style={{
            padding: "1rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>{message}</Typography>
          <div
            style={{ marginLeft: "1rem", cursor: "pointer" }}
            onClick={handleClose}
          >
            <Close />
          </div>
        </div>
      </Popover>
    </>
  );
};
