import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Layout from "../../components/Layout";
import ProfileCard from "../../components/ProfileCard";
import Grid from "@material-ui/core/Grid";
import MainTabArea from "./MainTabArea2";
import CustomCard from "../../components/CustomCard";
import CardList from "../../components/CardList";
import ExpandableCardList from "../../components/ExpandableCardList";
import CardLinkList from "../../components/CardLinkList";
import AlertCardList from "../../components/AlertCardList";
import {
  StartPostIcon,
  ProjectOwnerIcon,
  TeamMessageIcon,
  FeedIcon,
  NotificationIcon,
  TodoIcon,
} from "../../components/CustomIcons";
import TeamMessage from "../../views/team-messages/TeamMessage";
import Notification from "../../views/notifications/notifications";
import ProjectView from "../../views/project-view/ProjectView";
import VarialbleListView from "../../views/project-view/VarialbleListView2";
import { ThemeCardsProvider } from "../../components/ThemeCards";
import { CustomModal } from "../../components/Modal";
import CustomDialog from "../../components/Dialog";
import CreatePost from "./CreatePost";
import Feed from "./Feed";
import { Divider } from "@material-ui/core";
import InvitationModal from "./InvitationModal";
import { getCoverUrl, getProfilePic, getUser } from "../../utils/user";
import { useQuery } from "@apollo/client";
import {
  GET_NETWORK_MEMBERS,
  GET_NEW_NOTIFICATION_COUNT,
} from "../../graphql/query";
import { showErrroDialog } from "../../components/ErrorDialog";
import NetworkView from "./network-view";
import { InvitationSideBar } from "./InvitationSidebar";
import { SidebarCount } from "./SidebarCount";
import { NotificationManager } from "react-notifications";
import { darkGold, gold, lightBlack } from "../../constant";

const styles = (theme) => ({
  count: {
    marginRight: "15px",
  },
  root: {
    // minWidth: '700px',
    // background: theme.palette.background.secondary.main,
    minWidth: "100%",
  },
  summaryList: {
    display: "flex",
    justifyContent: "space-between",
  },
  Listtitle: {
    fontSize: "15px",
    fontWeight: "500px",
    color: "#0073b1",
    display: "flex",
    justifyContent: "center",
  },
  expandListItems: {
    borderTop: "2px solid #efefef",
    padding: "10px 0 0 0",
    margin: "10px 0 0 0",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  expandListTitle: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#283e4a",
  },
  newsCard: {
    marginTop: "10px",
  },
  newsTitle: {
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    fontWeight: "400",
    color: "#0073b1",
  },
  newsContent: {
    fontWeight: "400",
    color: "#707070",
    fontSize: "14px",
  },
  projectToolCard: {
    marginTop: "10px",
  },
  projectToolTitle: {
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    fontWeight: "400",
    color: "#0073b1",
  },
  projectToolContent: {
    fontWeight: "400",
    color: "#707070",
    fontSize: "14px",
  },
  aside: {
    width: "100%",
  },
  profile: {
    width: "100%",
  },
  rightSide: {
    width: "100%",
  },
  main: {
    width: "100%",
    flex: 1,
  },
});

const PageHome = ({ classes }) => {
  const [showCreatePostDialog, setShowCreatePostDialog] = useState(false);
  const [showInvitationModel, setShowInvitationModel] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [shouldRefetchInvitation, setShouldRefetchInvitation] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [entity_type_filter, setEntityTypeFilter] = useState(null);
  const [postType, setPostType] = useState("project");
  const { loading, data, error, refetch } = useQuery(
    GET_NEW_NOTIFICATION_COUNT
  );

  if (error) {
    showErrroDialog(
      error?.message || "An error occurred while getting new notification count"
    );
  }

  var tabs = [
    {
      label: "Start a post",
      icon: <StartPostIcon fontSize="large" />,
      panel: <div></div>,
      onClick: () => setShowCreatePostDialog(true),
    },
    {
      label: "Feed",
      icon: <FeedIcon fontSize="large" />,
      panel: (
        <Feed
          entity_type_filter={entity_type_filter}
          shouldRefetch={shouldRefetch}
          setShouldRefetch={setShouldRefetch}
        />
      ),
    },
    {
      label: "My Network",
      icon: <ProjectOwnerIcon fontSize="large" />,
      panel: (
        <NetworkView
          shouldRefetch={shouldRefetchInvitation}
          setShouldRefetch={setShouldRefetchInvitation}
          type="homepage"
          query={GET_NETWORK_MEMBERS}
          primaryDataObject="getNetworkMembers"
        />
      ),
    },
    {
      label: "Team Messages",
      icon: <TeamMessageIcon fontSize="large" />,
      panel: <TeamMessage />,
    },

    {
      label: data
        ? "Notification (" + data?.getNewNotificationsCount + ")"
        : "Notification",
      icon: <NotificationIcon fontSize="large" />,
      panel: <Notification />,
    },
    /* {
      label: "Todo's",
      icon: <TodoIcon fontSize="large" />,
      panel: <div></div>,
    },
    */
  ];
  const user = getUser();

  return (
    <Layout>
      <CreatePost
        postType={postType}
        shouldRefetch={shouldRefetch}
        setShouldRefetch={setShouldRefetch}
        showCreatePostDialog={showCreatePostDialog}
        showLinkToEvent={false}
        showLinkToProject={false}
        setShowCreatePostDialog={() => {
          setShowCreatePostDialog(false);

          setActiveTab(1);
        }}
      />
      <InvitationModal
        shouldRefetch={shouldRefetchInvitation}
        setShouldRefetch={setShouldRefetchInvitation}
        setActiveTab={(tabId) => {
          setShowInvitationModel(false);
          setActiveTab(tabId);
        }}
        showInvitationModel={showInvitationModel}
        setShowInvitationModel={setShowInvitationModel}
      />
      <Grid container spacing={3} fluid>
        <Grid item xs={12} md={3}>
          <aside className={classes.profile}>
            <Grid container xs="auto" spacing={1}>
              <Grid item xs={12} md={12} spacing={3}>
                <ProfileCard
                  displayName={user?.firstName + " " + user?.lastName}
                  username="paulnta"
                  avatarUrl="https://source.unsplash.com/collection/895539"
                  profileUrl={getProfilePic()}
                  coverUrl={getCoverUrl()}
                  stats={{
                    posts: 112,
                    followers: 234,
                    following: 22,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} spacing={1} id="test">
                <ThemeCardsProvider>
                  <CustomCard customStyle={{ margin: "8px" }}>
                    <CardLinkList
                      onClick={() => {
                        setShowCreatePostDialog(true);
                        setPostType("project");
                        setActiveTab(0);
                      }}
                      linkText="Create Project"
                    />
                    <CardLinkList
                      onClick={() => {
                        setPostType("announcement");
                        setShowCreatePostDialog(true);

                        setActiveTab(0);
                      }}
                      linkText="Create Announcement"
                    />
                    <CardLinkList
                      onClick={() => {
                        setPostType("event");
                        setShowCreatePostDialog(true);

                        setActiveTab(0);
                      }}
                      linkText="Create Event"
                    />
                    <Divider />
                    <CardLinkList
                      style={{ marginTop: "0.75rem" }}
                      onClick={() => setShowInvitationModel(true)}
                      linkText="Invite to Network"
                    />
                    {/*   <CardLinkList
                      onClick={() => setShowInvitationModel(true)}
                      linkText="Invitation Status"
                    /> */}
                    {/* <CardLinkList
											linkText='Invite Team Member'
										/> */}
                    {/* <CardLinkList
											linkText='Invite Mentor'
										/>
										<CardLinkList
											linkText='Invite Team Member'
										/> */}
                  </CustomCard>
                </ThemeCardsProvider>
              </Grid>
            </Grid>
          </aside>
        </Grid>

        <Grid item xs={12} md={6}>
          <MainTabArea
            setActiveTab={setActiveTab}
            tabs={tabs}
            activeTab={activeTab}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <aside className={classes.rightSide}>
            <div className={classes.fieldWorkSummary}>
              <ThemeCardsProvider>
                <div style={{ marginBottom: "1rem" }}>
                  <CustomCard cardTitle="Filter">
                    <div className={classes.expandListItems}>
                      <ExpandableCardList
                        onClick={() => {
                          setEntityTypeFilter("project");
                          setActiveTab(1);
                        }}
                        listTitle="Projects"
                      />
                      <ExpandableCardList
                        listTitle="Events"
                        onClick={() => {
                          setEntityTypeFilter("event");
                          setActiveTab(1);
                        }}
                      />
                      <ExpandableCardList
                        listTitle="Announcements"
                        onClick={() => {
                          setEntityTypeFilter("announcement");
                          setActiveTab(1);
                        }}
                      />
                      <ExpandableCardList
                        onClick={() => setActiveTab(2)}
                        listTitle="My Network"
                      />
                      <ExpandableCardList
                        listTitle="Similar Projects"
                        onClick={() =>
                          NotificationManager.info(
                            "This filter will be enabled soon"
                          )
                        }
                      />
                      <ExpandableCardList
                        onClick={() =>
                          NotificationManager.info(
                            "This filter will be enabled soon"
                          )
                        }
                        listTitle="Related Profiles"
                      />
                    </div>
                  </CustomCard>
                </div>
                <CustomCard cardTitle="Analytics">
                  <div className={classes.expandListItems}>
                    <SidebarCount />
                  </div>
                </CustomCard>
              </ThemeCardsProvider>

              {/* <CustomCard cardTitle="Today's News" customStyle={{marginTop: '10px'}}>
							<Typography className={classes.newsContent}>
								Important notes, comments, recent tasks, comments and messages. Project notifications
								and updates are received and displayed here.
							</Typography>
						</CustomCard>

						<CustomCard cardTitle="Project Tools" customStyle={{marginTop: '10px'}}>
								<Typography className={classes.projectToolContent}>
									This section contains the information about distinct tools required
									to be used to work upon the project.
							</Typography>
						</CustomCard> */}
              <InvitationSideBar />
            </div>
          </aside>
        </Grid>
      </Grid>
    </Layout>
  );
};

PageHome.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(PageHome);
