import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Carousel from "react-material-ui-carousel";
import { formatDateToMMDDYYY, fomatDateFromNow } from "../../utils/datetime";
import { useQuery } from "@apollo/client";
import {
  GET_EVENT_BY_ID,
  GET_EVENT_BY_ID_WITH_OWNER_DETAIL,
} from "../../graphql/query";
import Loader from "../../components/FullScreenLoader";
import ErrorText from "../../components/Error";
import ModelDetailListItem from "./ModelDetailListItem";
import DescriptionIcon from "@material-ui/icons/Description";
import EventIcon from "@material-ui/icons/Event";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LinkIcon from "@material-ui/icons/Link";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import PersonIcon from "@material-ui/icons/Person";
import { black, darkBlack, lightBlack } from "../../constant";

const useStyles = makeStyles((theme) => ({
  root: {
    background: lightBlack,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function ModelDetailCard({ data }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const {
    loading,
    data: eventDataFull,
    error,
  } = useQuery(GET_EVENT_BY_ID, {
    variables: { eventId: data.entity_id },
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const items = data.logo.split(",");

  if (loading) return <Loader />;

  if (error) {
    return <ErrorText message={error?.message} />;
  }

  const eventData = eventDataFull.getEvent.event;
  const creator = eventDataFull.getEvent.creator;

  const listData = [
    {
      name: "Description",
      value: eventData.description,
      icon: <DescriptionIcon />,
    },
    {
      name: "Type",
      value: eventData?.event_type?.displayText,
      icon: <CallSplitIcon />,
    },
    {
      name: "Creator",
      value: `${creator?.firstName} ${creator?.lastName}`,
      icon: <PersonIcon />,
    },

    {
      name: "Date",
      value: eventData?.date
        ? formatDateToMMDDYYY(eventData?.date)
        : "No Event Date has been added",
      icon: <EventIcon />,
    },
    {
      name: "Time",
      value: eventData?.time || "No Event Time has been added",
      icon: <AccessTimeIcon />,
    },
    {
      name: "Link",
      value: eventData?.link || "No Event Link has been added",
      icon: <LinkIcon />,
    },
  ];

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            src={items[0]}
            aria-label="recipe"
            className={classes.avatar}
          ></Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={data.name}
        style={{ borderBottom: "1px solid " + black }}
        subheader={fomatDateFromNow(eventData.createdAt)}
      />

      <CardContent>
        <Carousel>
          {items.map((item, i) => (
            <img src={item} style={{ height: "400px", width: "100%" }} />
          ))}
        </Carousel>
        <ModelDetailListItem data={listData} />
      </CardContent>
    </Card>
  );
}
