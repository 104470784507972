import React from "react";
import { black, darkBlack, gold } from "../../constant";
import { Chip, Typography } from "@material-ui/core";

export const StatusChip = ({ status }) => {
  if (!status) return null;
  if (Array.isArray(status)) {
    return status.map((item) => {
      if (item.toLowerCase() == "accepted") return <AcceptedStatus />;
      if (item.toLowerCase() == "pending") return <PendingStatus />;
      return <NormalStatus label={item} />;
    });
  } else {
    if (status.toLowerCase() == "accepted") return <AcceptedStatus />;
    return <NormalStatus label={status} />;
  }
};

export const AcceptedStatus = () => {
  return (
    <Typography variant="body2" style={{ marginLeft: "0.5rem" }}>
      <Chip
        size="small"
        label={"Accepted"}
        style={{
          background: black,
          color: gold,
        }}
      />
    </Typography>
  );
};

export const NormalStatus = ({ label }) => {
  return (
    <Typography variant="body2" style={{ marginLeft: "0.5rem" }}>
      <Chip size="small" label={label} />
    </Typography>
  );
};

export const PendingStatus = () => {
  return (
    <Typography variant="body2" style={{ marginLeft: "0.5rem" }}>
      <Chip size="small" label={"Pending"} />
    </Typography>
  );
};
