import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { useQuery } from "@apollo/client";

import Loader from "../../components/FullScreenLoader";
import ErrorText from "../../components/Error";
import { black, darkBlack, darkGold, lightBlack } from "../../constant";
import ListItem from "../Components/list-item";
import { formatUnixTimeStampToFromNow } from "../../utils/datetime";
import { getUser } from "../../utils/user";
import { GET_USER_IN_PROJECT } from "../../graphql/query";

const styles = (theme) => ({
  appBar: {
    backgroundColor: lightBlack,
    height: "50px",
  },
  tabContainerInner: {
    // border: '1px solid black',
    background: lightBlack,
  },
  listContainer: {
    width: "100%",
  },
  appTool: {
    minHeight: "50px",
  },
  projectListContainer: {
    padding: "5px 15px",
    background: theme.palette.background.primary.main,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },
  listHolder: {
    padding: "15px",
  },
  headerContainer: {
    marginBottom: "15px",
    borderBottom: "1px solid #ccc",
  },
  titleName: {
    zIndex: -20,
    color: "#000",
    fontSize: "1.2rem",
  },
});

function MembersView({ teamMemberRefetch, setTeamMemberRefetch, projectId }) {
  const { data, loading, error, refetch } = useQuery(GET_USER_IN_PROJECT, {
    variables: {
      projectId,
    },
  });

  console.log(data, loading, error, refetch);
  useEffect(() => {
    if (teamMemberRefetch) {
      setTeamMemberRefetch(false);
      refetch();
    }
  }, [teamMemberRefetch]);

  if (loading) return <Loader />;

  if (error) return <ErrorText message={error.message} />;

  return (
    <>
      <NetWorkList networks={data.getUserInProject} title="Invitation" />
      <div style={{ marginBottom: "2rem" }}></div>
    </>
  );
}

export const NetWorkList = withStyles(styles)(function ({ classes, networks }) {
  const loggedInUser = getUser();
  return (
    <div>
      <Grid container spacing={2}>
        <Grid container>
          <div className={classes.listContainer}>
            {networks.length == 0 && (
              <Typography style={{ textAlign: "center" }} variant="body1">
                No Members found
              </Typography>
            )}
            {networks.map((item) => {
              return (
                <ListItem
                  avatar={item.user?.profilePic}
                  title={item.user?.email}
                  subtitle={formatUnixTimeStampToFromNow(item.createdAt)}
                  status={item.status}
                />
              );
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
});

export default withStyles(styles)(MembersView);
