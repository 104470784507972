import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import ErrorText from "../../components/Error";
import Loader from "../../components/FullScreenLoader";
import { GET_PUBLIC_PROJECT } from "../../graphql/query";
import { Post } from "../PageHome/Feed";
import { fomatDateFromNow } from "../../utils/datetime";
import { useNavigate } from "react-router-dom";

export default function CommunityProject() {
  const { data, loading, error } = useQuery(GET_PUBLIC_PROJECT, {
    fetchPolicy: "network-only",
  });

  const navigate = useNavigate();
  if (loading) return <Loader />;

  if (error) return <ErrorText message={error?.message} />;

  return (
    <div>
      {data.getPublicProject.map((item) => {
        return (
          <Post
            onClick={() => navigate("/project/" + item.id)}
            item={{
              entity_type: "project",
              subtitle: fomatDateFromNow(parseInt(item.createdAt)),
              name: item.name,
              logo: item.logo,
              description: item.description,
            }}
          />
        );
      })}
    </div>
  );
}
