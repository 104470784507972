import React from "react";
import { SketchPicker } from "react-color";

export default class ColorPicker extends React.Component {
  handleChangeComplete = (color) => {
    this.props.setColor(color.hex);
  };

  render() {
    return (
      <SketchPicker
        color={this.props.color}
        onChangeComplete={this.handleChangeComplete}
      />
    );
  }
}
