import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import { SmallLoader } from "../../components/FullScreenLoader";
import { GET_EVENT_TYPES } from "../../graphql/query";
import { useQuery } from "@apollo/client";
import { GET_PROJECTS } from "../../graphql/mutation";
import MultiFileUpload from "./MultiFIleUpload";

export default function CreateEvent({
  eventName,
  handleEventNameChange,
  eventDescription,
  handleEventDescriptionChange,
  eventTypeId,
  handleEventTypeChange,
  handleFileChange,

  selectedProject,
  handleSelectedProjectChange,
  showLinkToProject,
  files,
  eventDate,
  eventTime,
  setEventDate,
  setEventTime,
  eventLink,
  setEventLink,
  isPublic,
  setIsPublic,
  projectId,
}) {
  const [showProjectLink, setShowProjectLink] = useState(false);
  const { data, loading, error } = useQuery(GET_EVENT_TYPES);
  const {
    data: projects,
    loading: getProjectLoading,
    error: getProjectError,
  } = useQuery(GET_PROJECTS);

  const handleProjectLinkChange = () => {
    setShowProjectLink(!showProjectLink);
  };

  return (
    <Paper>
      <TextField
        style={{ marginBottom: "1rem" }}
        fullWidth
        id="outlined-basic"
        label="Name"
        type="text"
        variant="outlined"
        value={eventName}
        onChange={handleEventNameChange}
      />
      <TextField
        multiline
        minRows={4}
        style={{ marginBottom: "1rem" }}
        fullWidth
        id="outlined-basic"
        label="Description"
        type="text"
        variant="outlined"
        value={eventDescription}
        onChange={handleEventDescriptionChange}
      />

      {loading ? (
        <SmallLoader />
      ) : (
        <FormControl fullWidth style={{ marginBottom: "1rem" }}>
          <InputLabel variant="outlined" id="project-select-label">
            Event Type
          </InputLabel>
          <Select
            variant="outlined"
            labelId="event-type-select-label"
            id="event-type-select"
            value={eventTypeId}
            onChange={handleEventTypeChange}
            label="Event Type"
          >
            {data.eventTypes.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.displayText}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <input
        value={eventDate}
        style={{
          borderRadius: "4px",
          border: "1px solid #ccc",
          width: "100%",
          padding: "1rem",
          marginBottom: "1rem",
        }}
        multiple
        type="date"
        onChange={(e) => setEventDate(e.target.value)}
      />
      <input
        value={eventTime}
        style={{
          borderRadius: "4px",
          border: "1px solid #ccc",
          width: "100%",
          padding: "1rem",
          marginBottom: "1rem",
        }}
        multiple
        type="time"
        onChange={(e) => setEventTime(e.target.value)}
      />

      <TextField
        style={{ marginBottom: "1rem" }}
        fullWidth
        id="outlined-basic"
        label="Event Link"
        type="text"
        variant="outlined"
        value={eventLink}
        onChange={(e) => setEventLink(e.target.value)}
      />
      <MultiFileUpload setFiles={handleFileChange} files={files} />
      {!projectId && (
        <div>
          <FormControlLabel
            style={{ marginTop: "1rem" }}
            labelPlacement="start"
            control={
              <Switch
                checked={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label="Public"
          />
        </div>
      )}

      {showLinkToProject && (
        <Button
          style={{ marginLeft: "0.5rem", marginBottom: "1rem" }}
          color={showProjectLink ? "primary" : "default"}
          onClick={handleProjectLinkChange}
        >
          Link to Project
        </Button>
      )}

      {showProjectLink &&
        (getProjectLoading ? (
          <SmallLoader />
        ) : (
          <FormControl fullWidth style={{ marginBottom: "1rem" }}>
            <InputLabel variant="outlined" id="project-select-label">
              Project
            </InputLabel>
            <Select
              variant="outlined"
              labelId="project-select-label"
              id="project-select"
              value={selectedProject}
              onChange={handleSelectedProjectChange}
              label="Project"
            >
              {projects.getProjects.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
    </Paper>
  );
}
