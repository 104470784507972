import moment from "moment";
export const formatDateToMMDDYYY = (date) => {
  const data = moment(date).format("MM-DD-YYYY");

  return data;
};

export const fomatDateFromNow = (date) => {
  const data = moment(date).fromNow();

  return data;
};

export const formatUnixTimeStampToFromNow = (unixDate) => {
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds
  var date = new Date(Number(unixDate));

  // Hours part from the timestamp

  const data = moment(date).fromNow();

  return data;
};
