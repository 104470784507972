import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { useMutation, useQuery } from "@apollo/client";
import { DELETE_USER_EVENT_ROLE, GET_EVENT_USERS } from "../../graphql/query";
import Loader, { SmallLoader } from "../../components/FullScreenLoader";
import ErrorText from "../../components/Error";

import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import { capitalizeFirstLetter } from "../../utils/stringUtil";
import {
  ADD_USER_TO_EVENT,
  UPDATE_USER_EVENT_ROLE,
} from "../../graphql/mutation";
import ListItem from "../Components/list-item";
import { getUser } from "../../utils/user";
import { NotificationManager } from "react-notifications";
import { black, darkBlack, darkGold, gold, lightBlack } from "../../constant";
import { useParams } from "react-router-dom";

const styles = (theme) => ({
  appBar: {
    backgroundColor: lightBlack,
    height: "50px",
  },
  tabContainerInner: {
    // border: '1px solid black',
    background: theme.palette.background.secondary.main,
  },
  listContainer: {
    width: "100%",
  },
  appTool: {
    minHeight: "50px",
    display: "flex",
    justifyContent: "space-between",
  },
  projectListContainer: {
    padding: "5px 15px",
    background: theme.palette.background.primary.main,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },
  listHolder: {
    padding: "15px",
  },
  headerContainer: {
    marginBottom: "15px",
    borderBottom: "1px solid #ccc",
  },
  titleName: {
    zIndex: -20,
  },
});

function EventMembersView({
  entity_id,
  getMemberToAddQuery,
  getMemberToAddQueryProp,
  getExistingMembersQuery,
  getExistingMembersQueryProp,
  update_user,
  delete_user,
  add_user,
  showAddMembers,
  type = "event",
}) {
  return (
    <>
      <NetWorkList
        title="Members"
        getMemberToAddQuery={getMemberToAddQuery}
        getMemberToAddQueryProp={getMemberToAddQueryProp}
        getExistingMembersQuery={getExistingMembersQuery}
        getExistingMembersQueryProp={getExistingMembersQueryProp}
        entity_id={entity_id}
        type={type}
        update_user={update_user}
        add_user={add_user}
        delete_user={delete_user}
        showAddMembers={showAddMembers}
      />
    </>
  );
}

const EventMemberRole = [
  "organizer",
  "participants",
  "creator",
  "registered",
  "attende",
  "presenter",
  "sender",
  "reciever",
];

// Function to find items in B not present in A based on userId
function findItemsNotInArrayA(arrayA, arrayB) {
  const userIdsInA = new Set(arrayA.map((item) => item.userId));
  return arrayB.filter((itemB) => !userIdsInA.has(itemB.userId));
}

export const NetWorkList = withStyles(styles)(function ({
  classes,
  getMemberToAddQuery,
  getMemberToAddQueryProp,
  getExistingMembersQuery,
  getExistingMembersQueryProp,
  entity_id,
  title,
  type = "event",
  update_user,
  delete_user,
  add_user,
  showAddMembers,
}) {
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [validationError, setValidationError] = useState(false);

  const variables =
    type == "event"
      ? { eventId: Number(entity_id) }
      : { announcementId: Number(entity_id) };

  const {
    data: membersToAdd,
    loading: membersToAddLoading,
    error: membersToAddError,
    refetch: membersToAddRefetch,
  } = useQuery(getMemberToAddQuery, {
    variables: variables,
  });

  const {
    data: existingMember,
    loading: exsitingMemberLoading,
    error: existingMemberError,
    refetch: existingMemberRefetch,
  } = useQuery(getExistingMembersQuery, {
    variables: variables,
  });

  const [
    updateUserRole,
    { loading: updateUserRoleLoading, error: updateUserRoleError },
  ] = useMutation(update_user);

  const [
    deleteUserRole,
    { loading: deleteUserRoleLoading, error: deleteUserRoleError },
  ] = useMutation(delete_user);

  const [showAddDiv, setShowAddDiv] = useState(false);
  const [addUser, { loading: addLoading, error: addError }] =
    useMutation(add_user);

  const handleSaveClick = async () => {
    try {
      if (!selectedUserId) {
        setValidationError("Please select a user");
        return;
      }
      if (!setSelectedRole) {
        setValidationError("Please select a role");
        return;
      }
      setValidationError(false);
      const variables =
        type == "event"
          ? {
              eventId: parseInt(entity_id),
              userId: parseInt(selectedUserId),
              role: selectedRole,
            }
          : {
              announcementId: parseInt(entity_id),
              userId: parseInt(selectedUserId),
              role: selectedRole,
            };
      const { data } = await addUser({
        variables: variables,
      });
      membersToAddRefetch();
      existingMemberRefetch();
    } catch (error) {}
  };
  if (exsitingMemberLoading || membersToAddLoading) {
    return <Loader />;
  }
  if (membersToAddError || existingMemberError) {
    return (
      <ErrorText
        message={membersToAddError?.message || existingMemberError?.message}
      />
    );
  }

  const user = getUser();

  const isOwner = existingMember
    ? existingMember[getExistingMembersQueryProp].filter(
        (item) => item.role == "owner" && item.user.id == user.id
      ).length > 0
      ? true
      : false
    : false;

  return (
    <div>
      {validationError && <ErrorText mb message={validationError} />}
      {addError && <ErrorText mb message={addError?.message} />}
      <Grid className={classes.tabContainerInner} container spacing={2}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.appTool}>
            <Typography
              variant="subtitle1"
              className={classes.titleName}
              style={{ color: "#000" }}
            >
              {title}
            </Typography>
            {isOwner && (
              <div style={{ display: "flex" }}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowAddDiv(!showAddDiv)}
                >
                  <Button style={{ background: darkBlack, color: darkGold }}>
                    Add Member
                  </Button>
                </div>
              </div>
            )}
          </Toolbar>
        </AppBar>

        {(updateUserRoleError || deleteUserRoleError) && (
          <ErrorText
            message={updateUserRole?.message || deleteUserRole?.message}
          />
        )}
        {showAddDiv && (
          <Card
            style={{
              padding: "0.5rem",
              width: "100%",
              margin: "1rem",
              marginBottom: 0,
            }}
          >
            {membersToAdd[getMemberToAddQueryProp].length == 0 ? (
              <ErrorText
                message={
                  "All the members in your network has already been added to the event"
                }
              />
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel variant="outlined" id="project-select-label">
                        Select Project Member
                      </InputLabel>
                      <Select
                        variant="outlined"
                        labelId="project-select-label"
                        id="project-select"
                        label="Select Project Member"
                        value={selectedUserId}
                        onChange={(e) => setSelectedUserId(e.target.value)}
                      >
                        {membersToAdd[getMemberToAddQueryProp].map((item) => (
                          <MenuItem value={item.userId}>
                            {item.firstName + " " + item.lastName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel variant="outlined" id="project-select-label">
                        Role
                      </InputLabel>
                      <Select
                        variant="outlined"
                        labelId="project-select-label"
                        id="project-select"
                        label="Role"
                        value={selectedRole}
                        onChange={(e) => setSelectedRole(e.target.value)}
                      >
                        {EventMemberRole.map((item) => (
                          <MenuItem value={item}>
                            {capitalizeFirstLetter(item)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    autoFocus
                    style={{ color: gold, background: darkBlack }}
                    variant="contained"
                    onClick={handleSaveClick}
                  >
                    {addLoading ? (
                      <SmallLoader width={20} strokeWidth={5} />
                    ) : (
                      "Save changes"
                    )}
                  </Button>
                </div>
              </>
            )}
          </Card>
        )}

        <Grid container className={classes.listHolder}>
          <div className={classes.listContainer}>
            {existingMember[getExistingMembersQueryProp] == 0 && (
              <Typography style={{ textAlign: "center" }} variant="body1">
                No members found
              </Typography>
            )}
            {existingMember[getExistingMembersQueryProp].map((item) => {
              let showEditButton = isOwner ? true : false;

              if (item.user.id == user.id) {
                showEditButton = false;
              }

              return (
                <ListItem
                  editDropDown={EventMemberRole}
                  editDropDownText={"Role"}
                  editValue={item.role}
                  showEditButton={showEditButton}
                  functionToCallOnValueChanged={async (value) => {
                    try {
                      console.log(
                        type == "event"
                          ? { userEventRoleId: item.id, role: value }
                          : {
                              userAnnouncementRoleId: item.id,
                              role: value,
                            }
                      );
                      await updateUserRole({
                        variables:
                          type == "event"
                            ? { userEventRoleId: item.id, role: value }
                            : {
                                userAnnouncementRoleId: item.id,
                                role: value,
                              },
                      });
                      NotificationManager.success(
                        "User event roles has been updated"
                      );
                      existingMemberRefetch();
                    } catch (error) {}
                  }}
                  editLoading={updateUserRoleLoading}
                  title={item.user.firstName + " " + item.user.lastName}
                  avatar={item?.user.profilePic || item.user.firstName}
                  subtitle={capitalizeFirstLetter(item.role)}
                  status={capitalizeFirstLetter(item.status)}
                  deleteLoading={deleteUserRoleLoading}
                  functionToCallForDelete={async () => {
                    try {
                      await deleteUserRole({
                        variables:
                          type == "event"
                            ? { userEventRoleId: item.id }
                            : {
                                userAnnouncementRoleId: item.id,
                              },
                      });
                      existingMemberRefetch();
                      membersToAddRefetch();
                      NotificationManager.success(
                        "User event roles has been deleted"
                      );
                    } catch (error) {}
                  }}
                />
              );
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
});

export default withStyles(styles)(EventMembersView);
