import * as React from "react";

import { Box } from "@material-ui/core";
import PublicProfile from "./public-profile";
import { useParams } from "react-router-dom";

export default function PublicProfileContainer() {
  return (
    <Box sx={{ display: "flex", minHeight: "100dvh" }}>
      <Box
        component="main"
        className="MainContent"
        sx={{
          pt: {
            xs: "calc(12px + var(--Header-height))",
            md: 3,
          },
          pb: {
            xs: 2,
            sm: 2,
            md: 3,
          },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          height: "100dvh",
          gap: 1,
          overflow: "auto",
        }}
      >
        <PublicProfile />
      </Box>
    </Box>
  );
}
