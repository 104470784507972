import React from "react";
import { Oval } from "react-loader-spinner";
import { black } from "../constant";

export default function Loader({
  color = "#4361ee",
  width = 200,
  strokeWidth = 2,
  strokeWidthSecondary = 2,
  height = 80,
  style = {},
}) {
  return (
    <div style={{ display: "flex", justifyContent: "center", ...style }}>
      <Oval
        width={width}
        color={color}
        wrapperStyle={{}}
        height={height}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#ddd"
        strokeWidth={strokeWidth}
        strokeWidthSecondary={strokeWidthSecondary}
      />
    </div>
  );
}

export const SmallLoader = ({ style = {}, color = black }) => {
  return (
    <Loader
      color={color}
      width={20}
      strokeWidth={5}
      height={20}
      style={style}
    />
  );
};
