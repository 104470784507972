import React, { useState } from "react";
import Basic from "../../components/FullScreenCalendar";
import { Post } from "../PageHome/Feed";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useQuery } from "@apollo/client";
import { GET_PUBLIC_EVENT } from "../../graphql/query";
import { fomatDateFromNow } from "../../utils/datetime";
import Loader from "../../components/FullScreenLoader";
import ErrorText from "../../components/Error";
import Layout from "../../components/Layout";
import { Grid } from "@material-ui/core";
import EventModal from "../EventModal";

export default function CommunityEvent() {
  const [value, setValue] = React.useState("calendar");

  const [showModal, setShowModal] = useState(false);
  const [dataForModal, setDataForModal] = useState({});

  const { data, loading, error } = useQuery(GET_PUBLIC_EVENT, {
    fetchPolicy: "network-only",
  });
  if (loading) return <Loader />;

  //if (error) return <ErrorText message={error?.message} />;
  return (
    <Layout>
      <EventModal
        dataForModal={dataForModal}
        showModal={showModal}
        setShowModal={setShowModal}
        showAddMembers={false}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "2rem",
        }}
      >
        <Switcher value={value} setValue={setValue} />
      </div>

      <Grid container spacing={3} fluid>
        <Grid item xs={12} md={3}>
          <aside></aside>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{ background: "#ddd", marginTop: "2rem" }}
        >
          {value == "box" ? (
            data.getPublicEvent.map((item) => {
              return (
                <Post
                  onClick={() => {
                    setDataForModal((prev) => ({
                      ...item,
                      entity_id: item.id,
                    }));
                    setShowModal(true);
                  }}
                  item={{
                    entity_type: "event",
                    subtitle: fomatDateFromNow(parseInt(item.createdAt)),
                    name: item.name,
                    logo: item.logo
                      ? item.logo.split(",")[0]
                      : "https://placehold.co/100x100",
                    description: item.description,
                  }}
                />
              );
            })
          ) : (
            <div style={{ height: "500px" }}>
              <Basic
                onClick={(item) => {
                  setDataForModal((prev) => ({
                    ...item,
                    entity_id: item.id,
                  }));
                  setShowModal(true);
                }}
                query={GET_PUBLIC_EVENT}
                queryKey="getPublicEvent"
              />
            </div>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
}

export const Switcher = ({ value, setValue }) => {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        style={{ display: "flex", flexDirection: "row" }}
        aria-label="gender"
        name="gender1"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value="box" control={<Radio />} label="Box" />
        <FormControlLabel
          value="calendar"
          control={<Radio />}
          label="Calendar"
        />
      </RadioGroup>
    </FormControl>
  );
};
