// Import necessary dependencies from React and Material-UI
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";

// Define the RadioGroupComponent functional component
const Type = () => {
  const [selectedValue, setSelectedValue] = useState("question");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        row
        aria-label="radio-group"
        name="radio-group"
        value={selectedValue}
        onChange={handleChange}
      >
        <FormControlLabel
          value="question"
          control={<Radio />}
          label="Question"
        />
        <FormControlLabel
          value="statement"
          control={<Radio />}
          label="Statement"
        />
        <FormControlLabel value="task" control={<Radio />} label="Task" />
        <FormControlLabel
          value="reminder"
          control={<Radio />}
          label="Reminder"
        />
      </RadioGroup>

      {/* Conditionally render QuestionComponent when "Question" is selected */}
      {selectedValue === "question" && <QuestionComponent />}
      {selectedValue === "task" && <TaskComponent />}
      {selectedValue === "statement" && <StatementComponent />}

      
    </FormControl>
  );
};

// Export the RadioGroupComponent for use in other parts of the application
export default Type;

// Define the QuestionComponent functional component
const QuestionComponent = () => {
  const [question, setQuestion] = useState("");
  const [questionType, setQuestionTypel] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [hint, setHint] = useState("");
  const [explanation, setExplanation] = useState("");

  return (
    <div>
      {/* Text field for Question */}
      <TextField
        fullWidth
        label="Question"
        multiline
        minRows={4}
        variant="outlined"
        margin="normal"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      />

      {/* Text field for Label */}
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel id="element-type-label">Question Type</InputLabel>
        <Select
          labelId="element-type-label"
          label="Question Type"
          value={questionType}
          onChange={(e) => setQuestionTypel(e.target.value)}
        >
          <MenuItem value="text">Text</MenuItem>
          <MenuItem value="checkbox">Checkbox</MenuItem>
          <MenuItem value="radio">Radio Button</MenuItem>
          <MenuItem value="range">Range</MenuItem>
        </Select>
      </FormControl>

      {/* Select box for difficulty */}
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel id="difficulty-label">Difficulty</InputLabel>
        <Select
          labelId="difficulty-label"
          label="Difficulty"
          value={difficulty}
          onChange={(e) => setDifficulty(e.target.value)}
        >
          <MenuItem value="easy">Easy</MenuItem>
          <MenuItem value="medium">Medium</MenuItem>
          <MenuItem value="hard">Hard</MenuItem>
        </Select>
      </FormControl>

      {/* Text field for Hint */}
      <TextField
        fullWidth
        label="Hint"
        variant="outlined"
        multiline
        minRows={4}
        margin="normal"
        value={hint}
        onChange={(e) => setHint(e.target.value)}
      />

      {/* Text field for Explanation */}
      <TextField
        fullWidth
        label="Explanation"
        variant="outlined"
        multiline
        minRows={4}
        margin="normal"
        value={explanation}
        onChange={(e) => setExplanation(e.target.value)}
      />
    </div>
  );
};

const TaskComponent = () => {
  // State for storing values
  const [taskType, setTaskType] = useState("");
  const [taskName, setTaskName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [taskImportance, setTaskImportance] = useState(5);
  const [isValidated, setIsValidated] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [taskDuration, setTaskDuration] = useState("");
  const [taskCost, setTaskCost] = useState("");

  return (
    <div>
      <h2>Task Component</h2>

      {/* Text field for Task Type */}
      <TextField
        fullWidth
        label="Task Type"
        variant="outlined"
        style={{ marginBottom: "1rem" }}
        margin="normal"
        value={taskType}
        onChange={(e) => setTaskType(e.target.value)}
      />

      {/* Text field for Task Name */}
      <TextField
        fullWidth
        label="Task Name"
        variant="outlined"
        margin="normal"
        style={{ marginBottom: "1rem" }}
        value={taskName}
        onChange={(e) => setTaskName(e.target.value)}
      />

      {/* Date picker for Task Start Date */}
      <input
        value={startDate}
        style={{
          borderRadius: "4px",
          border: "1px solid #ccc",
          width: "100%",
          padding: "1rem",
          marginBottom: "1rem",
          marginTop: "1rem",
        }}
        multiple
        type="date"
        onChange={(e) => setStartDate(e.target.value)}
      />

      {/* Date picker for Task End Date */}
      <input
        value={endDate}
        style={{
          borderRadius: "4px",
          border: "1px solid #ccc",
          width: "100%",
          padding: "1rem",
          marginBottom: "2rem",
          marginTop: "1rem",
        }}
        multiple
        type="date"
        onChange={(e) => setEndDate(e.target.value)}
      />

      {/* Slider for Task Importance */}
      <div style={{ marginBottom: "1rem", display: "flex" }}>
        <Typography
          style={{ color: "rgba(0, 0, 0, 0.5)" }}
          id="discrete-slider"
          gutterBottom
        >
          Task Importance
        </Typography>
        <Slider
          defaultValue={30}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={1}
          marks
          min={1}
          max={10}
        />
      </div>

      {/* Switch for Is Validated */}
      <div style={{ marginBottom: "1rem" }}>
        <FormControlLabel
          style={{ color: "rgba(0, 0, 0, 0.5)" }}
          control={
            <Switch
              floa
              checked={isValidated}
              onChange={() => setIsValidated(!isValidated)}
            />
          }
          label="Is Validated"
        />
      </div>

      <div style={{ marginBottom: "1rem" }}>
        <FormControlLabel
          style={{ color: "rgba(0, 0, 0, 0.5)" }}
          control={
            <Switch
              checked={isCompleted}
              onChange={() => setIsCompleted(!isCompleted)}
            />
          }
          label="Is Completed"
        />
      </div>

      <TextField
        fullWidth
        label="Task Duration"
        type="number"
        variant="outlined"
        margin="normal"
        value={taskDuration}
        style={{ marginBottom: "1rem" }}
        onChange={(e) => setTaskDuration(e.target.value)}
      />

      {/* Number input field for Task Cost */}
      <TextField
        fullWidth
        label="Task Cost"
        type="number"
        variant="outlined"
        margin="normal"
        value={taskCost}
        onChange={(e) => setTaskCost(e.target.value)}
      />
    </div>
  );
};

const StatementComponent = () => {
  const [statementText, setStatementTet] = useState("");
  const [statementDescription, setStatmentDescription] = useState("");

  return (
    <div>
      {/* Text field for Question */}
      <TextField
        fullWidth
        label="Text"
        multiline
        minRows={4}
        variant="outlined"
        margin="normal"
        value={statementText}
        onChange={(e) => setStatementTet(e.target.value)}
      />

      {/* Text field for Hint */}
      <TextField
        fullWidth
        label="Description"
        variant="outlined"
        multiline
        minRows={4}
        margin="normal"
        value={statementDescription}
        onChange={(e) => setStatmentDescription(e.target.value)}
      />

      {/* Text field for Explanation */}
    </div>
  );
};
