import { Button, CardContent, Grid, Card } from "@material-ui/core";
import React, { useState } from "react";
import MultiFileUpload from "../../PageHome/MultiFIleUpload";
import { showErrorModal } from "../../../components/Error";
import { uploadFile } from "../../../utils/uploadFIle";

export default function ImageAssets({ item, updateDataModal }) {
  const [files, setFile] = useState(null);

  const handleUpload = async () => {
    try {
      console.log("ff", item);
      const promises = await Array.from(files).map(async (file) => {
        const uploaded = await uploadFile(file);
        console.log("ipdd", uploaded);
        return uploaded;
      });
      console.log("orr", promises);
      const uploaded = await Promise.all(promises);
      console.log("ii", uploaded);
      if (item.imageAssets) {
        item.imageAssets = [...uploaded, ...item.imageAssets];
      } else {
        item.imageAssets = uploaded;
      }
      updateDataModal(item);
    } catch (error) {
      console.log(error);
      showErrorModal("An error occured while uploading file, please try again");
    }
  };
  return (
    <div>
      <Card style={{ maxWidth: "600px" }}>
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid item>
              <MultiFileUpload setFiles={(e) => setFile(e.target.files)} />
            </Grid>

            <Button onClick={handleUpload} variant="contained" color="primary">
              Upload
            </Button>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Grid container spacing={2}>
            {item.imageAssets
              ? item.imageAssets.map((item) => {
                  return (
                    <Grid item md={3}>
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={item}
                      />
                    </Grid>
                  );
                })
              : "No Image found"}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
