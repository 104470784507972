import { useMutation } from "@apollo/client";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import CustomizedDialogs from "../../components/Dialog";
import { ADD_TO_NETWORK } from "../../graphql/mutation";
import { NotificationManager } from "react-notifications";

export default function InvitationModal({
  showInvitationModel,
  setShowInvitationModel,
  setActiveTab,
  shouldRefetch,
  setShouldRefetch,
}) {
  const [email, setEmail] = useState("");
  const [validationError, setValidationError] = useState("");
  const [addToNetwork, { data, error, loading }] = useMutation(ADD_TO_NETWORK);

  const onAddClick = async () => {
    try {
      if (!validateEmail(email)) {
        setValidationError("Invalid email address");
        return;
      }
      if (!email) {
        setValidationError("Please enter email");
        return;
      }
      setValidationError(false);
      await addToNetwork({ variables: { email } });
      setEmail("");
      NotificationManager.success("Invitation sent");
      setShowInvitationModel(false);
      setShouldRefetch(true);
    } catch {}
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  return (
    <CustomizedDialogs
      loading={loading}
      error={error?.message || validationError}
      open={showInvitationModel}
      onClose={setShowInvitationModel}
      handleSaveClick={onAddClick}
      title="Invite to Network"
      content={
        <>
          <Typography
            variant="subtitle1"
            style={{ marginBottom: "1rem" }}
            gutterBottom
          >
            Enter the email of the person you wish to Invite. You can check the
            status of invite by clicking on{" "}
            <span
              onClick={() => setActiveTab(2)}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Invitation Status
            </span>
          </Typography>

          <TextField
            style={{ marginBottom: "1rem" }}
            fullWidth
            id="outlined-basic"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            variant="outlined"
          />
          {/*  <FormControl fullWidth style={{ marginBottom: "1rem" }}>
        <InputLabel variant="outlined" id="demo-simple-select-label">
          Post Type
        </InputLabel> */}
          {/* <Select
    
          variant="outlined"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          label="Age"
          onChange={(e) => setType(e.target.value)}
        >
          <MenuItem value={"project"}>Project</MenuItem>
          <MenuItem value={"event"}>Event</MenuItem>
          <MenuItem value={"announcement"}>Announcement</MenuItem>
        </Select> */}
          {/*     </FormControl> */}
          {/*  {type == "project" && <CreateProject />}
        {type == "announcement" && <CreateAnnouncement />}
        {type == "event" && <CreateEvent />} */}
        </>
      }
    />
  );
}
