import React, { useState } from "react";
import CustomizedDialogs from "../../../components/Dialog";
import Content from "../Content";
import Details from "./Details";
import ImageAssets from "./ImageAssets";
import Type from "./Type";

export default function DetailModal({ item, data, onClose, updateDataModal }) {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    {
      label: "Details",

      panel: <Details item={item} />,
    },
    {
      label: "Type",

      panel: <Type item={item} />,
    },
    {
      label: "Image Assets",

      panel: <ImageAssets updateDataModal={updateDataModal} item={item} />,
    },
    {
      label: "Comments",

      panel: <div>To Be Added</div>,
    },
  ];
  

  return (
    <CustomizedDialogs
      size="lg"
      title={item.title}
      open={true}
      onClose={onClose}
      showSaveButton={false}
      content={
        <Content
          tabItemStyle={{ flex: 1 }}
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      }
    ></CustomizedDialogs>
  );
}
