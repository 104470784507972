import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import StarIcon from "@material-ui/icons/Star";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link } from "react-router-dom";
import {
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  Check,
  CheckBox,
  Clear,
  DeleteRounded,
  EditOutlined,
  EditRounded,
  FolderSpecialOutlined,
} from "@material-ui/icons";
import { capitalizeFirstLetter } from "../../utils/stringUtil";
import Loader, { SmallLoader } from "../../components/FullScreenLoader";
import {
  black,
  blueTextCOlor,
  darkBlack,
  gold,
  lightBlack,
} from "../../constant";
import { AcceptedStatus, StatusChip } from "./status";
import { FaBell, FaCross } from "react-icons/fa6";

const styles = (theme) => ({
  projectListContainer: {
    padding: "5px 15px",
    background: lightBlack,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
    padding: "1rem",
  },
  listHolder: {
    padding: "15px",
  },
  avatar: {
    display: "inline-block",
    height: 35,
    width: 35,
    // border: `5px solid ${theme.palette.common.white}`,
    background: theme.palette.grey[200],
    zIndex: 999,
  },
  avatarSec: {
    display: "inline-block",
    height: 35,
    width: 35,
    background: theme.palette.grey[200],
    position: "relative",
    left: "-10px",
  },
  headerContainer: {
    marginBottom: "15px",
    borderBottom: "1px solid #ccc",
  },
});

function ListItemWithAction({
  classes,
  title,
  subtitle,
  showActionButton = false,
  acceptLoading = false,
  declineLoading = false,
  acceptClick,
  declineClick,
  ...props
}) {
  return (
    <List className={classes.root}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar style={{ background: black }}>
            <FaBell />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          style={{ color: "black" }}
          color="black"
          primary={title}
          secondary={subtitle}
        />
        {showActionButton && (
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={acceptClick}>
              {acceptLoading ? (
                <SmallLoader />
              ) : (
                <Check
                  style={{
                    background: darkBlack,
                    color: gold,
                    borderRadius: "50%",
                  }}
                />
              )}
            </IconButton>

            <IconButton edge="end" aria-label="delete" onClick={declineClick}>
              {declineLoading ? (
                <SmallLoader />
              ) : (
                <Clear
                  style={{
                    background: darkBlack,
                    color: gold,
                    borderRadius: "50%",
                    marginLeft: "0.5rem",
                  }}
                />
              )}
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>

      <Divider />
    </List>
  );
}

export default withStyles(styles)(ListItemWithAction);
