import React, { useEffect, useState } from "react";

import CustomizedDialogs from "../../components/Dialog";

import {
  FeedIcon,
  StartPostIcon,
  TeamMessageIcon,
} from "../../components/CustomIcons";

import Content from "../PageProject/Content";

import Tab1 from "./tab1";
import {
  GET_ANNOUNCEMENT_USERS,
  GetNetworkMembersToAddToAnnnouncement,
} from "../../graphql/query";
import EventMemberView from "../EventModal/EventMemberView";
import {
  ADD_USER_TO_ANNOUNCEMENT,
  DELETE_USER_ANNOUNCEMENT_ROLE,
  UPDATE_USER_ANNOUNCEMENT_ROLE,
} from "../../graphql/mutation";
import { Comments } from "../PageProject/Comments";

const AnnouncementModal = ({
  dataForModal,
  setShowModal,
  showModal,
  showAddMembers = true,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  var tabs = [
    {
      label: "Details",
      icon: <StartPostIcon fontSize="large" />,
      panel: <Tab1 data={dataForModal} />,
    },
    {
      label: "Members",
      icon: <FeedIcon fontSize="large" />,
      panel: (
        <EventMemberView
          showAddMembers={showAddMembers}
          type="announcement"
          getMemberToAddQuery={GetNetworkMembersToAddToAnnnouncement}
          getMemberToAddQueryProp={"getNetworkMembersToAddToAnnouncement"}
          getExistingMembersQuery={GET_ANNOUNCEMENT_USERS}
          getExistingMembersQueryProp={"getAnnouncementUsers"}
          entity_id={dataForModal?.entity_id}
          title={dataForModal.title}
          update_user={UPDATE_USER_ANNOUNCEMENT_ROLE}
          delete_user={DELETE_USER_ANNOUNCEMENT_ROLE}
          add_user={ADD_USER_TO_ANNOUNCEMENT}
        />
      ),
    },
    {
      label: "Comments",
      icon: <TeamMessageIcon fontSize="large" />,
      panel: (
        <Comments entity_id={dataForModal?.entity_id} type="announcement" />
      ),
    },
  ];
  return (
    <>
      <CustomizedDialogs
        showSaveButton={false}
        onClose={() => setShowModal(false)}
        size={"md"}
        open={showModal}
        content={
          <>
            <Content
              tabItemStyle={{ flex: 1 }}
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </>
        }
      />
    </>
  );
};

export default AnnouncementModal;
