import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import CustomCard from "../../components/CustomCard";
import { ThemeCardsProvider } from "../../components/ThemeCards";
import {
  GET_SIDEBAR_COUNT,
  GET_TOP_FIVE_INVITATION,
} from "../../graphql/query";
import Loader from "../../components/FullScreenLoader";
import ErrorText from "../../components/Error";
import { capitalizeFirstLetter } from "../../utils/stringUtil";
import AlertCardList from "../../components/AlertCardList";
import { Typography } from "@material-ui/core";
import CardList from "../../components/CardList";

export const SidebarCount = () => {
  const { data, loading, error } = useQuery(GET_SIDEBAR_COUNT);

  if (loading) return <Loader width={20} />;

  if (error) return <ErrorText message={error?.message} />;
  const {
    getSidebarCount: {
      project,
      connections,
      assignedProjects,
      events,
      assignedEvents,
      announcements,
      assignedAnnouncements,
    },
  } = data;
  return (
    <>
      <CardList text="Created Projects" count={project} />
      <CardList text="Assigned Projects" count={connections} />
      <CardList text="Created Events" count={assignedProjects} />
      <CardList text="Assigned Events" count={events} />
      <CardList text="Announcements" count={assignedEvents} />
      <CardList text="Assigned Announcements" count={announcements} />
      <CardList text="Connections" count={assignedAnnouncements} />
    </>
  );
};
