import moment from "moment";

export const formatEvent = (events) => {
  // HNIX timestamp in milliseconds

  return events.map((item) => {
    return {
      id: item.id,
      start: getMomentDate(item.date, item.time),
      end: getMomentDate(item.date, item.time),
      title: item.name,
    };
  });
};

const getMomentDate = (eventDate, time) => {
  console.log("eeve", eventDate, time);
  // Convert HNIX timestamp to a moment object
  const date = moment(parseInt(eventDate));

  // Set the time to the date object
  const result = date.set({
    hour: parseInt(time.split(":")[0]),
    minute: parseInt(time.split(":")[1]),
    second: parseInt(time.split(":")[2]),
  });
  return result.toDate();
};
