import { gql } from "@apollo/client";

export const GET_LOGGED_IN_USER = gql`
  query getLoggedInUser($jwt: String!) {
    loggedInUserViaJwt(jwt: $jwt) {
      email
      firstName
      lastName
      id
      institution {
        id
        name
      }
      institutionId
      phoneNumber
      description
      profilePic
      role {
        displayText
        id
      }
    }
  }
`;

export const GET_NOTIFICATIOSN = gql`
  query getNotifications {
    notifications {
      id
      text
      actionCompletedIdToUpdate
      actionCompletedText
      isActionTaken
      createdAt
    }
  }
`;

export const GET_NEW_NOTIFICATION_COUNT = gql`
  query getNotificationCount {
    getNewNotificationsCount
  }
`;

export const GET_EVENT_TYPES = gql`
  query getEventTypes {
    eventTypes {
      displayText
      id
    }
  }
`;

export const GET_ANNOUNCEMENT_TYPES = gql`
  query getAnnouncementTypes {
    announcementType {
      id
      displayText
    }
  }
`;

export const GET_EVENTS = gql`
  query getEvents {
    getEvents {
      id
      name
    }
  }
`;

export const CREATE_PROJECTS = gql`
  query getProjects {
    getProjects {
      id
      name
      logo
      description
      users {
        email
        firstName
        lastName
      }
    }
  }
`;

export const GET_EVENT_BY_PROJECT_ID = gql`
  query getEventsByProjectId($projectId: ID!) {
    getEventByProjectId(projectId: $projectId) {
      name
      description
      users {
        email
        user_event_role {
          id
          role
        }
      }
    }
  }
`;
export const GET_PROJECT_EVENT_AND_ANNOUNCEMENT = gql`
  query getProjectAnnouncementAndEvents($projectId: Int!) {
    getProjectAnnouncementAndEvents(projectId: $projectId) {
      email
      entity_id
      entity_type
      userid
      name
      firstname
      lastname
      logo
      description
    }
  }
`;

export const GET_PROJECT_WITH_OWNER = gql`
  query ($project_id: ID!) {
    getProjectWithOwner(id: $project_id) {
      project {
        id
        name
        description
        logo
        tags
        createdAt
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_NETWORK_MEMBERS = gql`
  query {
    getNetworkMembers {
      id
      createdAt
      sender {
        id
        email
        profilePic
      }
      recipient {
        id
        email
        profilePic
      }
      status
    }
  }
`;

export const GET_NETWORK_MEMBERS_TO_ADD_TO_PROJECT = gql`
  query GetNetworkMemberstoAddToProject($projectId: Int!) {
    getNetworkMemberstoAddToProject(projectId: $projectId) {
      email
      firstName
      lastName
      profilePic
      userId
    }
  }
`;

export const GET_INSTITUTIONS_QUERY = gql`
  query getInstitutions($keyword: String!) {
    getInstitutions(keyword: $keyword) {
      id
      name
    }
  }
`;

export const GET_HOME_FEED = gql`
  query GetHomeFeed(
    $pageSize: Int!
    $pageNumber: Int!
    $entity_type_filter: String
  ) {
    getHomeFeed(
      page_size: $pageSize
      page_number: $pageNumber
      entity_type_filter: $entity_type_filter
    ) {
      data {
        email
        entity_id
        entity_type
        userid
        name
        firstname
        lastname
        logo
        description
        entity_role
      }
      count
    }
  }
`;

export const GET_PROJECT_FEED = gql`
  query GetHomeFeed(
    $pageSize: Int!
    $pageNumber: Int!
    $entity_type_filter: String
    $project_id: Int!
  ) {
    getProjectFeed(
      page_size: $pageSize
      page_number: $pageNumber
      entity_type_filter: $entity_type_filter
      project_id: $project_id
    ) {
      data {
        email
        entity_id
        entity_type
        userid
        name
        firstname
        lastname
        logo
        description
        entity_role
      }
      count
    }
  }
`;

export const GET_EVENT_BY_ID = gql`
  query GetEvent($eventId: ID!) {
    getEvent(id: $eventId) {
      creator {
        id
        firstName
        lastName
        email

        profilePic
      }
      event {
        createdAt
        id
        name
        description
        logo

        event_type {
          displayText
        }
        date
        time
        link
      }
    }
  }
`;

export const GET_EVENT_USERS = gql`
  query getEventUsers($getEventUsersId: Int!) {
    getEventUsers(id: $getEventUsersId) {
      firstName
      lastName
      email
      role
      status
      userId
      createdAt
      profilePic
    }
  }
`;

export const GET_COMMENTS = gql`
  query getComment($entity_id: Int!, $type: String!) {
    getComment(type: $type, entity_id: $entity_id) {
      text
      image
      createdAt
      user {
        firstName
        profilePic
        lastName
      }
    }
  }
`;

export const GET_PUBLIC_PROJECT = gql`
  query getPublicProject {
    getPublicProject {
      createdAt
      name
      id
      description
      logo
    }
  }
`;

export const GET_PUBLIC_EVENT = gql`
  query GetPublicEvent($startDate: String, $endDate: String) {
    getPublicEvent(startDate: $startDate, endDate: $endDate) {
      createdAt
      date
      description
      time
      id
      link
      logo
      name
    }
  }
`;

export const GET_PUBLIC_USER = gql`
  query ($offset: Int!, $limit: Int!) {
    getPublicUser(offset: $offset, limit: $limit) {
      count
      user {
        email
        lastName
        firstName
        id
        profilePic
        role {
          displayText
        }
      }
    }
  }
`;

export const GET_PUBLIC_USER_BY_iD = gql`
  query GetPublicUserProfile($userId: Int!) {
    getPublicUserProfile(user_id: $userId) {
      user {
        firstName
        lastName
        description
        phoneNumber
        profilePic
        email
        role {
          displayText
        }
        institution {
          name
        }
      }
      isInNetwork
      invitationStatus
    }
  }
`;

export const GET_PUBLIC_INSTITUTION = gql`
  query ($offset: Int!, $limit: Int!) {
    getPublicInsitutions(offset: $offset, limit: $limit) {
      count
      institutions {
        id
        name
        country
        url
      }
    }
  }
`;

export const GetNetworkMembersToAddToEvent = gql`
  query GetNetworkMembersToAddToEvent($eventId: Int!) {
    getNetworkMembersToAddToEvent(eventId: $eventId) {
      userId
      firstName
      lastName
      profilePic
    }
  }
`;

export const GetNetworkMembersToAddToAnnnouncement = gql`
  query GetNetworkMembersToAddToAnnouncement($announcementId: Int!) {
    getNetworkMembersToAddToAnnouncement(announcementId: $announcementId) {
      userId
      firstName
      lastName
      profilePic
    }
  }
`;

export const GET_EVENT_USER = gql`
  query GetEventUsers($eventId: Int!) {
    getEventUsers(eventId: $eventId) {
      role
      id
      status
      user {
        id
        email
        firstName
        lastName
        profilePic
      }
    }
  }
`;

export const GET_ANNOUNCEMENT_USERS = gql`
  query GetAnnouncementUsers($announcementId: Int!) {
    getAnnouncementUsers(announcementId: $announcementId) {
      role
      id
      status
      user {
        id
        email
        firstName
        lastName
        profilePic
      }
    }
  }
`;

export const DELETE_USER_EVENT_ROLE = gql`
  mutation DeleteUserEventRole($userEventRoleId: Int!) {
    deleteUserEventRole(userEventRoleId: $userEventRoleId)
  }
`;

export const GET_ANNOUNCEMENT_BY_ID = gql`
  query GetAnnouncement($getAnnouncementId: ID!) {
    getAnnouncement(id: $getAnnouncementId) {
      announcement_type {
        displayText
        name
      }
      description
      id
      logo
      name
      createdAt
    }
  }
`;

export const GET_TOP_FIVE_INVITATION = gql`
  query GetTopFiveInvitation {
    getTopFiveInvitation {
      created_at
      entity_id
      entity_role
      entity_type
      fromFirstName
      fromLastName
      invitation_status
      name
      user_id
    }
  }
`;

export const GET_SIDEBAR_COUNT = gql`
  query GetSidebarCount {
    getSidebarCount {
      project
      connections
      assignedProjects
      events
      assignedEvents
      announcements
      assignedAnnouncements
    }
  }
`;

export const GET_PROJECT_SIDEBAR_COUNT = gql`
  query ($projectId: Int!) {
    getProjectSidebarCount(projectId: $projectId) {
      eventsCount
      announcementCount
      projectMembers
    }
  }
`;

export const GET_USER_IN_PROJECT = gql`
  query GetUserInProject($projectId: Int!) {
    getUserInProject(projectId: $projectId) {
      id
      role
      status
      projectId
      createdAt
      user {
        firstName
        email
        profilePic
        lastName
      }
    }
  }
`;

export const GET_CONTENT_ITEM = gql`
  query ContentItem($projectId: Int!, $containerType: String!) {
    contentItem(project_id: $projectId, containerType: $containerType) {
      description
      id
      name
      rationale
      type
      user {
        firstName
        lastName
      }
    }
  }
`;

export const GET_CONTENT_ITEM_GROUP = gql`
  query ContentItemGroup($projectId: Int!) {
    contentItemGroup(project_id: $projectId) {
      name
      description
      color
      id
      user {
        firstName
        lastName
        email
      }
    }
  }
`;
