import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Layout from "../../components/Layout";
import ProjectDetailsCard from "../../components/ProjectDetailsCard2";
import Grid from "@material-ui/core/Grid";
import CustomCard from "../../components/CustomCard";
import CardList from "../../components/CardList";
import CardLinkList from "../../components/CardLinkList";
import AlertCardList from "../../components/AlertCardList";
import Avatar from "@material-ui/core/Avatar";
import { BrowserRouter as Router, useParams } from "react-router-dom";
import {
  StartPostIcon,
  ProjectOwnerIcon,
  TeamMessageIcon,
  FeedIcon,
  NotificationIcon,
  TodoIcon,
} from "../../components/CustomIcons";
import TeamMessage from "../../views/team-messages/TeamMessage";
import Notification from "../../views/notifications/notifications";
import ProjectView from "../../views/project-view/ProjectView";

import { ThemeCardsProvider } from "../../components/ThemeCards";
import Content from "./Content";
import CreatePost from "../PageHome/CreatePost";

import { useQuery } from "@apollo/client";
import {
  GET_HOME_FEED,
  GET_PROJECT_BY_ID,
  GET_PROJECT_FEED,
  GET_PROJECT_WITH_OWNER,
} from "../../graphql/query";
import Loader from "../../components/FullScreenLoader";
import ErrorText from "../../components/Error";
import ProjectInvitationModel from "./ProjectInvitationModel";
import ProjectMembersView from "./MembersView";
import SwotAnalysis from "./SwatAnalysis";
import ProfileCard from "../../components/ProfileCard";
import { Divider } from "@material-ui/core";
import MainTabArea2 from "../PageHome/MainTabArea2";
import ExpandableCardList from "../../components/ExpandableCardList";
import { NotificationManager } from "react-notifications";
import { SidebarCount } from "../PageHome/SidebarCount";
import { InvitationSideBar } from "../PageHome/InvitationSidebar";
import ProjectProfileCard from "./ProjectProfileCard";
import { getUser } from "../../utils/user";
import Feed from "../PageHome/Feed";
import { ProjectRequest } from "./ProjectRequest";
import { ProjectSidebarCount } from "./ProjectSideBarCount";

const styles = (theme) => ({
  count: {
    marginRight: "15px",
  },
  root: {
    // minWidth: '700px',
    minWidth: "100%",
  },
  summaryList: {
    display: "flex",
    justifyContent: "space-between",
  },
  Listtitle: {
    fontSize: "15px",
    fontWeight: "500px",
    color: "#0073b1",
    display: "flex",
    justifyContent: "center",
  },
  summaryText: {
    fontSize: "12px",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.6)",
  },
  summaryCount: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#0073b1",
  },
  expandListItems: {
    borderTop: "2px solid #efefef",
    padding: "10px 0 0 0",
    margin: "10px 0 0 0",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  expandListTitle: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#283e4a",
  },
  newsCard: {
    marginTop: "10px",
  },
  newsTitle: {
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    fontWeight: "400",
    color: "#0073b1",
  },
  newsContent: {
    fontWeight: "400",
    color: "#707070",
    fontSize: "14px",
  },
  projectToolCard: {
    marginTop: "10px",
  },
  projectToolTitle: {
    display: "flex",
    justifyContent: "center",
    fontSize: "15px",
    fontWeight: "400",
    color: "#0073b1",
  },
  projectToolContent: {
    fontWeight: "400",
    color: "#707070",
    fontSize: "14px",
  },
  aside: {
    width: "100%",
  },
  profile: {
    width: "100%",
  },
  rightSide: {
    width: "100%",
  },
  main: {
    width: "100%",
    flex: 1,
  },
});

const PageProject = ({ classes, props }) => {
  const [showLeftAndRightBar, setShowLeftAndRightBar] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const { projectId } = useParams();

  const [showCreatePostDialog, setShowCreatePostDialog] = useState(false);
  const [showInvitationModel, setShowInvitationModel] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [postType, setPostType] = useState("event");
  const [teamMemberRefetch, setTeamMemberRefetch] = useState(false);
  const [entity_type_filter, setEntityTypeFilter] = useState(null);

  const { data, loading, error, refetch } = useQuery(GET_PROJECT_WITH_OWNER, {
    variables: { project_id: projectId },
  });

  // SAMPLE PROJECTS

  var tabs = [
    {
      label: "Start a post",
      icon: <StartPostIcon fontSize="large" />,
      panel: <div></div>,
      onClick: () => setShowCreatePostDialog(true),
    },
    {
      label: "Project Feed",
      icon: <FeedIcon fontSize="large" />,
      panel: data?.getProjectWithOwner ? (
        <Feed
          queryKey="getProjectFeed"
          entity_type_filter={entity_type_filter}
          shouldRefetch={shouldRefetch}
          setShouldRefetch={setShouldRefetch}
          query={GET_PROJECT_FEED}
          queryVariables={{ project_id: Number(projectId) }}
        />
      ) : (
        <></>
      ),
    },

    {
      label: "Team Members",
      icon: <TeamMessageIcon fontSize="large" />,
      panel: data?.getProjectWithOwner ? (
        <>
          <ProjectMembersView
            projectId={Number(projectId)}
            teamMemberRefetch={teamMemberRefetch}
            setTeamMemberRefetch={setTeamMemberRefetch}
          />
        </>
      ) : (
        <></>
      ),
    },

    {
      label: "SWOT Analysis",
      icon: <FeedIcon fontSize="large" />,
      panel: <SwotAnalysis setShowLeftAndRightBar={setShowLeftAndRightBar} />,
    },
    /*
    {
      label: "Metrics",
      icon: <NotificationIcon fontSize="large" />,
      panel: <Notification />,
    },
    {
      label: "Resources",
      icon: <TodoIcon fontSize="large" />,
      panel: <div></div>,
    },
    {
      label: "Assets",
      icon: <TeamMessageIcon fontSize="large" />,
      panel: <TeamMessage />,
    },
    {
      label: "Respond",
      icon: <ProjectOwnerIcon fontSize="large" />,
      panel: <ProjectView />,
    },
    */
  ];

  if (loading) return <Loader />;

  if (error)
    return (
      <Layout>
        <ErrorText message={error?.message || "Something went wrong"} />
      </Layout>
    );

  const project = data.getProjectWithOwner.project;
  const owner = data.getProjectWithOwner.user;
  const role = data.getProjectWithOwner.role;

  const user = getUser();
  return (
    <Layout>
      {showInvitationModel && (
        <ProjectInvitationModel
          shouldRefetch={shouldRefetch}
          setTeamMemberRefetch={setTeamMemberRefetch}
          showInvitationModel={showInvitationModel}
          setShowInvitationModel={setShowInvitationModel}
          projectId={projectId}
        />
      )}
      {showCreatePostDialog && (
        <CreatePost
          shouldRefetch={shouldRefetch}
          setShouldRefetch={setShouldRefetch}
          postType={postType}
          showProjectDropdown={false}
          showCreatePostDialog={showCreatePostDialog}
          showLinkToProject={false}
          projectId={projectId}
          setShowCreatePostDialog={() => {
            setShowCreatePostDialog(false);
            setActiveTab(1);
          }}
        />
      )}

      <Grid container spacing={3} fluid>
        {activeTab != 3 && (
          <Grid item xs={12} md={3}>
            <aside className={classes.profile}>
              <Grid container xs="auto" spacing={1}>
                <Grid item xs={12} md={12} spacing={3}>
                  <ProjectProfileCard
                    displayName={owner?.firstName + " " + owner?.lastName}
                    username="paulnta"
                    avatarUrl="https://source.unsplash.com/collection/895539"
                    profileUrl={project.name}
                    coverUrl={project.logo}
                    showTags={true}
                    owner={owner}
                    description={project.description}
                    tags={project.tags ? project.tags.split(",") : ["No Tags"]}
                  />
                </Grid>
                <Grid item xs={12} md={12} spacing={1} id="test">
                  <ThemeCardsProvider>
                    <CustomCard customStyle={{ margin: "8px" }}>
                      <CardLinkList
                        onClick={() => {
                          setPostType("announcement");
                          setShowCreatePostDialog(true);

                          setActiveTab(0);
                        }}
                        linkText="Create Announcement"
                      />
                      <CardLinkList
                        onClick={() => {
                          setPostType("event");
                          setShowCreatePostDialog(true);

                          setActiveTab(0);
                        }}
                        linkText="Create Event"
                      />
                      <Divider />
                      {user.id == owner.id && (
                        <CardLinkList
                          style={{ marginTop: "0.75rem" }}
                          onClick={() => setShowInvitationModel(true)}
                          linkText="Invite to Project"
                        />
                      )}
                    </CustomCard>
                  </ThemeCardsProvider>
                </Grid>
              </Grid>
            </aside>
          </Grid>
        )}

        <Grid item xs={12} md={activeTab == 3 ? 12 : 6}>
          <MainTabArea2
            setActiveTab={setActiveTab}
            tabs={tabs}
            activeTab={activeTab}
          />
        </Grid>

        {activeTab != 3 && (
          <Grid item xs={12} md={3}>
            <aside className={classes.rightSide}>
              <div className={classes.fieldWorkSummary}>
                <ThemeCardsProvider>
                  <div style={{ marginBottom: "1rem" }}>
                    <CustomCard cardTitle="Filter">
                      <div className={classes.expandListItems}>
                        <ExpandableCardList
                          listTitle="Events"
                          onClick={() => {
                            setEntityTypeFilter("event");
                            setActiveTab(1);
                          }}
                        />
                        <ExpandableCardList
                          listTitle="Announcements"
                          onClick={() => {
                            setEntityTypeFilter("announcement");
                            setActiveTab(1);
                          }}
                        />
                        <ExpandableCardList
                          onClick={() => setActiveTab(2)}
                          listTitle="Project Members"
                        />
                      </div>
                    </CustomCard>
                  </div>
                  <CustomCard cardTitle="Analytics">
                    <div className={classes.expandListItems}>
                      <ProjectSidebarCount />
                    </div>
                  </CustomCard>
                </ThemeCardsProvider>

                {/* <CustomCard cardTitle="Today's News" customStyle={{marginTop: '10px'}}>
							<Typography className={classes.newsContent}>
								Important notes, comments, recent tasks, comments and messages. Project notifications
								and updates are received and displayed here.
							</Typography>
						</CustomCard>

						<CustomCard cardTitle="Project Tools" customStyle={{marginTop: '10px'}}>
								<Typography className={classes.projectToolContent}>
									This section contains the information about distinct tools required
									to be used to work upon the project.
							</Typography>
						</CustomCard> */}
                {owner.id == user.id && <ProjectRequest />}
              </div>
            </aside>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};

PageProject.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(PageProject);
