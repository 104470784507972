import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import CustomCard from "../../components/CustomCard";
import { ThemeCardsProvider } from "../../components/ThemeCards";
import {
  GET_PROJECT_SIDEBAR_COUNT,
  GET_SIDEBAR_COUNT,
  GET_TOP_FIVE_INVITATION,
} from "../../graphql/query";
import Loader from "../../components/FullScreenLoader";
import ErrorText from "../../components/Error";
import { capitalizeFirstLetter } from "../../utils/stringUtil";
import AlertCardList from "../../components/AlertCardList";
import { Typography } from "@material-ui/core";
import CardList from "../../components/CardList";
import { useParams } from "react-router-dom";

export const ProjectSidebarCount = () => {
  const { data, loading, error } = useQuery(GET_PROJECT_SIDEBAR_COUNT, {
    variables: {
      projectId: useParams().projectId,
    },
  });

  if (loading) return <Loader width={20} />;

  if (error) return <ErrorText message={error?.message} />;
  const {
    getSidebarCount: { eventsCount, announcementCount, projectMembers },
  } = data;
  return (
    <>
      <CardList text="Project Members" count={projectMembers} />
      <CardList text="Events" count={eventsCount} />
      <CardList text="Announcement Events" count={announcementCount} />
    </>
  );
};
