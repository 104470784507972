import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { CaretDownIcon } from "../../components/CustomIcons";
import TeamMessageItem from "../../components/TeamMessageItem";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
  notificationsContainer: {
    borderRadius: "3px",
    fontSize: "16px",
    fontWeight: "500",
    color: "#ffffff",
  },

  notificationsHead: {
    backgroundColor: "rgba(0, 115, 177, 0.6)",
    borderRadius: "3px",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    // padding: '5px 2px'
  },
  bellIcon: {
    margin: "-1px 5px",
    padding: "0",
  },
  headIcon: {
    margin: "-1px 5px",
    padding: "0",
    verticalAlign: "middle",
  },
  headButton: {
    float: "right",
    background: "#0073B1",
    border: "none",
    padding: "5px 20px",
    color: "#fff",
  },
  headSearchContainer: {
    margin: "8px 0px",
  },
  headSearch: {
    width: "100%",
    padding: "10px",
  },
  teamHeadTitle: {
    paddingLeft: "10px",
    color: "#66ABD0",
  },
  headTitle: {
    position: "relative",
    top: "-5px",
  },
});

function TeamMessage(props) {
  const { classes } = props;

  return (
    <Typography variant="subtitle2" style={{ color: "#000" }}>
      Coming Soon
    </Typography>
  );
}

TeamMessage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(TeamMessage);
