import React from "react";
import Swal from "sweetalert2";

export const showErrroDialog = ( message ) => {
  Swal.fire({
    title: "Error!",
    text: message,
    icon: "error",
  });
};
