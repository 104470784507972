import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { useSpring, animated } from "react-spring"; // web.cjs is required for IE 11 support
import {
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import CustomCard from "./CustomCard";
import { HighlightOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    minWidth: "20rem",
    //padding: theme.spacing(2, 4, 3),
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export const CustomModal = ({title, content, onClose}) => {
  const [type, setType] = React.useState("announcement");
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button type="button" onClick={handleOpen}>
        react-spring
      </button>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade style={{ width: "30rem" }} in={open}>
          <Paper style={{ padding: "1rem", border: 0 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography variant="h5" gutterBottom>
                  Create Project
                </Typography>
              </Grid>
              <Grid item>
                <HighlightOff />
              </Grid>
            </Grid>
            <Divider />
            <Paper style={{marginTop:"1rem"}}>
            {content}
            </Paper>

         {/*    <FormControl fullWidth style={{ marginBottom: "1rem" }}>
              <InputLabel variant="outlined" id="demo-simple-select-label">
                Post Type
              </InputLabel>
              <Select
                variant="outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={10}
                label="Age"
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value={"project"}>Project</MenuItem>
                <MenuItem value={"event"}>Event</MenuItem>
                <MenuItem value={"announcement"}>Announcement</MenuItem>
              </Select>
            </FormControl>
            {type == "project" && <CreateProject />}
            {type == "announcement" && <CreateAnnouncement />} */}
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};

const CreateProject = () => {
  return (
    <>
      
    </>
  );
};

const CreateAnnouncement = () => {
  return (
    <>
      {/*   <FormControl fullWidth style={{marginBottom:"1rem"}}>
  <InputLabel variant="outlined" id="demo-simple-select-label">Project</InputLabel>
  <Select
  variant="outlined"
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={10}
    label="Age"
    
  >
    <MenuItem value={10}>Project 1</MenuItem>
    <MenuItem value={20}>Project 2</MenuItem>
    <MenuItem value={30}>Project 3</MenuItem>
  </Select>
</FormControl> */}
      <TextField
        style={{ marginBottom: "1rem" }}
        fullWidth
        id="outlined-basic"
        label="Name"
        type="text"
        variant="outlined"
      />
      <TextField
        style={{ marginBottom: "1rem" }}
        fullWidth
        id="outlined-basic"
        label="Description"
        type="text"
        variant="outlined"
      />
      <FormControl fullWidth style={{ marginBottom: "1rem" }}>
        <InputLabel variant="outlined" id="demo-simple-select-label">
          Announcement type
        </InputLabel>
        <Select
          variant="outlined"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={10}
          label="Age"
        >
          <MenuItem value={10}>Annoucne ment type 1</MenuItem>
          <MenuItem value={20}>Announcement type 2</MenuItem>
          <MenuItem value={30}>Announcement type 3</MenuItem>
        </Select>
      </FormControl>
      <input
        style={{
          borderRadius: "4px",
          border: "1px solid #ccc",
          width: "100%",
          padding: "1rem",
        }}
        type="file"
      />
      <ButtonGroup
        style={{ marginTop: "1rem" }}
        variant="text"
        color="primary"
        aria-label="text primary button group"
      >
        <Button>Link to Project</Button>
        <Button>Link to Event</Button>
      </ButtonGroup>
    </>
  );
};
