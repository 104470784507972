import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function ErrorText({ message, mb, style = {} }) {
  const classes = useStyles();
  let uStyle = { ...style };
  if (mb) uStyle.marginBottom = "1rem";
  return (
    <div className={classes.root} style={uStyle}>
      <Alert severity="error">{message || "Something went wrong"}</Alert>
    </div>
  );
}
export const showErrorModal = (message) => {
  Swal.fire({
    title: "Error!",
    text: message,
    icon: "error",
  });
};
