import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import StarIcon from "@material-ui/icons/Star";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link } from "react-router-dom";
import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { DeleteRounded, EditOutlined, EditRounded } from "@material-ui/icons";
import { capitalizeFirstLetter } from "../../utils/stringUtil";
import Loader from "../../components/FullScreenLoader";
import { blueTextCOlor, darkBlack, lightBlack } from "../../constant";
import { AcceptedStatus, StatusChip } from "./status";

const styles = (theme) => ({
  projectListContainer: {
    padding: "5px 15px",
    background: lightBlack,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
    padding: "1rem",
  },
  listHolder: {
    padding: "15px",
  },
  avatar: {
    display: "inline-block",
    height: 35,
    width: 35,
    // border: `5px solid ${theme.palette.common.white}`,
    background: theme.palette.grey[200],
    zIndex: 999,
  },
  avatarSec: {
    display: "inline-block",
    height: 35,
    width: 35,
    background: theme.palette.grey[200],
    position: "relative",
    left: "-10px",
  },
  headerContainer: {
    marginBottom: "15px",
    borderBottom: "1px solid #ccc",
  },
});

function ListItem({
  classes,
  avatar,
  title,
  subtitle,
  status,
  color,
  editDropDown,
  editDropDownText,
  editValue,
  setEditValue,
  showEditButton,
  functionToCallOnValueChanged,
  editLoading = false,
  functionToCallForDelete,
  deleteLoading,
  ...props
}) {
  const [showEditSelect, setShowEditSelect] = useState(false);

  const [changedValue, setChangedValue] = useState(editValue);
  console.log("ittt", title);
  return (
    // <div>
    <Grid container className={classes.projectListContainer}>
      <div>
        <Link to={props.projectLink}>
          <div
            style={{
              display: "flex",
              alignItems: subtitle ? "null" : "center",
            }}
          >
            <Avatar src={avatar} style={{ marginRight: "0.5rem" }}></Avatar>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="subtitle2" style={{ color: "#000" }}>
                {title}
              </Typography>
              <Typography
                variant="body2"
                style={{ color: "#000", fontSize: "0.8rem" }}
              >
                {subtitle}
              </Typography>
            </div>
          </div>
        </Link>
      </div>
      {showEditSelect ? (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <FormControl>
              <InputLabel variant="outlined" id="project-select-label">
                {editDropDownText}
              </InputLabel>
              <Select
                variant="outlined"
                labelId="project-select-label"
                id="project-select"
                label="Role"
                value={changedValue}
                onChange={async (e) => {
                  await functionToCallOnValueChanged(e.target.value);
                  setShowEditSelect(false);
                }}
              >
                {editDropDown.map((item) => (
                  <MenuItem value={item}>
                    {capitalizeFirstLetter(item)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {editLoading && (
              <div style={{ marginLeft: "0.5rem" }}>
                <Loader color={darkBlack} width={20} />
              </div>
            )}
          </div>
        </>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <StatusChip status={status} />

          {showEditButton && (
            <EditRounded
              style={{ marginLeft: "0.5rem", color: blueTextCOlor }}
              onClick={() => setShowEditSelect(true)}
            />
          )}
          {!deleteLoading && showEditButton && (
            <DeleteRounded
              color="red"
              style={{ marginLeft: "0.5rem", color: "red" }}
              onClick={() => functionToCallForDelete()}
            />
          )}
          {deleteLoading && (
            <div style={{ marginLeft: "0.5rem" }}>
              <Loader color={darkBlack} width={20} />
            </div>
          )}
        </div>
      )}
    </Grid>
  );
}

export default withStyles(styles)(ListItem);
