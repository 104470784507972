import { useQuery } from "@apollo/client";
import { Pagination } from "@material-ui/lab";
import React, { useState } from "react";
import ErrorText from "../../components/Error";
import Loader from "../../components/FullScreenLoader";
import { GET_PUBLIC_INSTITUTION } from "../../graphql/query";

import ListItem from "./ListItem";

export default function CommunityInstitutiion() {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, loading, error, refetch } = useQuery(GET_PUBLIC_INSTITUTION, {
    variables: {
      offset,
      limit,
    },
  });

  if (loading) return <Loader />;
  if (error) return <ErrorText message={error?.message} />;

  return (
    <div>
      {data.getPublicInsitutions.institutions.map((item) => {
        console.log("tt", item);
        return (
          <ListItem
            profilePic={null}
            role={`${item.country}, ${item.url}`}
            email={item.name}
          />
        );
      })}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          onChange={(e, p) => {
            refetch({
              variables: {
                offset: (p - 1) * 10,
                limit,
              },
            });
            setOffset((p - 1) * 10);
            setCurrentPage(p);
          }}
          page={currentPage}
          style={{ marginTop: "2rem" }}
          color="primary"
          count={Math.ceil(data.getPublicInsitutions.count / limit)}
        />
      </div>
    </div>
  );
}
