import React, { useEffect, useState } from "react";

import CustomizedDialogs from "../../components/Dialog";

import {
  FeedIcon,
  StartPostIcon,
  TeamMessageIcon,
} from "../../components/CustomIcons";

import { Comments } from "../PageProject/Comments";

import Content from "../PageProject/Content";
import EventMembersView from "./EventMemberView";
import { EventAnnouncementDetail } from "../PageProject/ModelDetails";
import {
  DELETE_USER_EVENT_ROLE,
  GET_EVENT_USER,
  GetNetworkMembersToAddToEvent,
} from "../../graphql/query";
import {
  ADD_USER_TO_EVENT,
  UPDATE_USER_EVENT_ROLE,
} from "../../graphql/mutation";

const EventModal = ({
  dataForModal,
  setShowModal,
  showModal,
  showAddMembers = true,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  var tabs = [
    {
      label: "Details",
      icon: <StartPostIcon fontSize="large" />,
      panel: <EventAnnouncementDetail data={dataForModal} />,
    },
    {
      label: "Members",
      icon: <FeedIcon fontSize="large" />,
      panel: (
        <EventMembersView
          showAddMembers={showAddMembers}
          getMemberToAddQuery={GetNetworkMembersToAddToEvent}
          getMemberToAddQueryProp={"getNetworkMembersToAddToEvent"}
          getExistingMembersQuery={GET_EVENT_USER}
          getExistingMembersQueryProp={"getEventUsers"}
          entity_id={dataForModal?.entity_id}
          title={dataForModal.title}
          update_user={UPDATE_USER_EVENT_ROLE}
          delete_user={DELETE_USER_EVENT_ROLE}
          add_user={ADD_USER_TO_EVENT}
        />
      ),
    },
    {
      label: "Comments",
      icon: <TeamMessageIcon fontSize="large" />,
      panel: <Comments entity_id={dataForModal?.entity_id} />,
    },
  ];
  return (
    <>
      <CustomizedDialogs
        showSaveButton={false}
        onClose={() => setShowModal(false)}
        size={"md"}
        open={showModal}
        content={
          <>
            <Content
              tabItemStyle={{ flex: 1 }}
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </>
        }
      />
    </>
  );
};

export default EventModal;
