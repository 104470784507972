import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import { AspectRatio, EditOutlined } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import ErrorText from "../../../components/Error";
import Loader from "../../../components/FullScreenLoader";
import Layout from "../../../components/Layout";
import SuccessText from "../../../components/SuccessText";
import { UPDATE_USER } from "../../../graphql/mutation";
import { uploadFile } from "../../../utils/uploadFIle";
import InstitutionInput from "./CountrySelector";
import CountrySelect from "./CountrySelector";

export default function MyProfile() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [institution, setInstitution] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [validationError, setValidationError] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [tempFileUrl, setTempFileUrl] = useState("");
  const [institutionName, setInstitutionName] = useState("");
  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const [success, setSuccess] = useState(false);

  const [updateUser, { loading, error }] = useMutation(UPDATE_USER);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    if (!file.type.startsWith("image/")) {
      setValidationError("Please select a valid image file.");
      return;
    }
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setTempFileUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const user = JSON.parse(localStorage.getItem("user"));
  console.log("uss", user);
  useEffect(() => {
    setFirstName(user.firstName);
    setLastName(user.lastName || "");
    setEmail(user.email);
    setPhoneNumber(user.phoneNumber || "");
    setInstitution(user.institution?.id || "");
    setRole(user.role.displayText);
    setDescription(user.description || "");
    setInstitutionName(user.institution?.name);
    setTempFileUrl(user.profilePic || "");
  }, []);

  const handleSaveClick = async () => {
    if (!firstName) {
      setValidationError("First Name is required");
      return;
    }
    if (!lastName) {
      setValidationError("Last Name is required");
      return;
    }
    if (!phoneNumber) {
      setValidationError("Phone NUmber is required");
      return;
    }
    if (!description) {
      setValidationError("Description is required");
      return;
    }
    if (!institution) {
      setValidationError("Institution is required");
      return;
    }

    setValidationError(false);
    try {
      let uploaded = null;
      if (selectedFile) {
        setFileUploadLoading(true);
        uploaded = await uploadFile(selectedFile);
        setFileUploadLoading(false);
      }
      let variables = {
        firstName,
        lastName,
        phoneNumber: phoneNumber.toString(),
        description,

        institutionId: institution,
      };
      if (uploaded) variables.profilePic = uploaded;
      await updateUser({ variables: variables });
      setSuccess(true);
      user.description = description;
      user.profilePic = uploaded;
      user.phoneNumber = phoneNumber;
      user.institution = {
        name: institutionName,
        id: institution,
      };
      user.institutionId = institution;
      localStorage.setItem("user", JSON.stringify(user));
    } catch (err) {}
  };

  const getDivStyle = () => {
    const backgroundImage = tempFileUrl
      ? `"${tempFileUrl}"`
      : "https://placehold.co/200x200";

    return {
      height: "10rem",
      borderRadius: "50%",
      backgroundImage: `url(${backgroundImage})`,
    };
  };
  return (
    <Layout>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileInputChange}
      />
      <Box
        style={{
          maxWidth: "800px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "5rem",
        }}
      >
        <Card style={{ background: "aliceblue" }}>
          <CardHeader
            titleTypographyProps={{ fontSize: "1.3rem" }}
            style={{ borderBottom: "1px solid #dddfe2" }}
            title={"Edit Profile"}
            subheader="Customize how your profile will appear to the network"
          ></CardHeader>

          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <div
                  style={{
                    position: "relative",
                    borderRadius: "inherit",

                    paddingBottom:
                      "calc(var(--AspectRatio-paddingBottom) - 2 * var(--variant-borderWidth, 0px))",
                    overflow: "hidden",
                    transition: "inherit",
                    fontFamily:
                      'var(--joy-fontFamily-body, "Inter", var(--joy-fontFamily-fallback, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"))',
                    fontSize: "var(--joy-fontSize-md, 1rem)",
                    lineHeight: "var(--joy-lineHeight-md, 1.5)",
                    color:
                      "var(--variant-softColor, var(--joy-palette-neutral-softColor, var(--joy-palette-neutral-700, #32383E)))",
                    "-variantBorderWidth": "0px",
                  }}
                >
                  <div style={getDivStyle()}></div>

                  <IconButton
                    onClick={handleButtonClick}
                    style={{
                      position: "absolute",
                      right: "20px",
                      bottom: "12px",
                      background: "#fff",
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                </div>
              </Grid>
              <Grid item xs={12} md={9}>
                <div style={{ marginBottom: "2rem" }}>
                  {success && (
                    <SuccessText message={"Profile updated successfully"} />
                  )}
                  {(validationError || error) && (
                    <ErrorText message={validationError || error?.message} />
                  )}
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      disabled
                      size="small"
                      style={{ marginBottom: "1rem" }}
                      fullWidth
                      id="outlined-basic"
                      label="Email"
                      type="text"
                      value={email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      disabled
                      size="small"
                      value={role}
                      style={{ marginBottom: "1rem" }}
                      fullWidth
                      id="outlined-basic"
                      label="Role"
                      type="text"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      style={{ marginBottom: "1rem" }}
                      fullWidth
                      id="outlined-basic"
                      label="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      type="text"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      style={{ marginBottom: "1rem" }}
                      fullWidth
                      id="outlined-basic"
                      label="Last Name"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      size="small"
                      style={{ marginBottom: "1rem" }}
                      fullWidth
                      id="outlined-basic"
                      label="Phone Number"
                      type="text"
                      variant="outlined"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InstitutionInput
                      institutionId={institution}
                      institutionName={institutionName}
                      setInstitution={(id, label) => {
                        setInstitution(id);
                        setInstitutionName(label);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      multiline
                      minRows={4}
                      size="small"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      style={{ marginBottom: "1rem" }}
                      fullWidth
                      id="outlined-basic"
                      label="Description"
                      type="text"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={handleSaveClick}
                    autoFocus
                    variant="contained"
                    color="primary"
                  >
                    {loading || fileUploadLoading ? (
                      <Loader width={20} strokeWidth={5} />
                    ) : (
                      "Save changes"
                    )}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Layout>
  );
}
