import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import UserNotification from "../../components/UserNotification";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  GET_NEW_NOTIFICATION_COUNT,
  GET_NOTIFICATIOSN,
} from "../../graphql/query";
import Loader from "../../components/FullScreenLoader";
import ErrorComponent from "../../components/Error";
import {
  ACCEPT_NOTIFICATIOn,
  DECLINE_NOTIFICATION,
  SET_ALL_NOTIFICATION_TO_VIEWED,
} from "../../graphql/mutation";
import { client } from "../../graphql";
import ErrorText from "../../components/Error";
import ListItemWithAction from "../../routes/Components/list-item-with-action";
import { formatUnixTimeStampToFromNow } from "../../utils/datetime";
import { NotificationManager } from "react-notifications";

const styles = (theme) => ({
  menuBarArticle: {
    border: "0.5 solid " + theme.palette.background.primary.main,
    borderRadius: "5px",
    marginBottom: "20px",
  },
  menuTabs: {
    width: "100%",
  },
  menuBar: {
    backgroundColor: theme.palette.background.secondary.main,
    // width: '100%',
    borderRadius: "5px",
    borderBottomRightRadius: "0",
    borderBottomLeftRadius: "0",
    borderBottom: "none",
    boxShadow: "none  ",
  },
  articleArea: {
    backgroundColor: theme.palette.background.secondary.main,

    borderRadius: "5px",
    borderTopRightRadius: "0",
    borderTopLeftRadius: "0",
    borderBottom: "none",
    padding: "10px",
  },
  articleText: {
    width: "100%",
    border: "0",
    resize: "none",
    backgroundColor: theme.palette.background.secondary.main,
  },
  newPostBtn: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  newbtn: {
    borderRadius: "25px",
    // color: "#ffffff",
    backgroundColor: theme.palette.background.secondary.main,
    fontSize: "16px",
    fontWeight: "500",
    width: "7.5rem",
    height: "32px",
    boxShadow: "none",
    position: "relative",
  },
  btnTextNew: {
    lineHeight: "3px",
  },
  lineIcon: {
    position: "absolute",
    left: "32px",
    top: "10px",
  },
  arrowUpIcon: {
    position: "absolute",
    top: "8px",
    left: "28px",
  },
  notificationsContainer: {
    borderRadius: "3px",
    fontSize: "16px",
    fontWeight: "500",
    // color: "#ffffff"
  },

  notificationsHead: {
    backgroundColor: "rgba(0, 115, 177, 0.6)",
    borderRadius: "3px",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
    // padding: '5px 2px'
  },
  tabItemsCustom: {
    // border: "1px solid #c9c9c9",
    fontSize: "10px",
    minWidth: "20%",
  },
  bellIcon: {
    margin: "-1px 5px",
    padding: "0",
    // minWidth: '20%'
  },
  root: {
    // minWidth: '700px',
    minWidth: "100%",
  },
  file: {
    width: "100%",
  },
  main: {
    width: "100%",
    flex: 1,
  },
});

function TeamMessage(props) {
  const { classes } = props;

  const { loading, error, data, refetch } = useQuery(GET_NOTIFICATIOSN);

  const [mutateFunction] = useMutation(SET_ALL_NOTIFICATION_TO_VIEWED);

  const [acceptNotification, { loading: acceptLoading, error: accptError }] =
    useMutation(ACCEPT_NOTIFICATIOn);

  const [
    declineNotification,
    { loading: declineLoading, error: declineError },
  ] = useMutation(DECLINE_NOTIFICATION);

  const onAcceptClick = async (notification) => {
    try {
      await acceptNotification({
        variables: { notificationAcceptedId: parseInt(notification.id) },
      });
      NotificationManager.success("Invitation Accepted");
      refetch();
    } catch (error) {}
  };

  const onDeclineClick = async (notification) => {
    try {
      await declineNotification({
        variables: { notificationDeclinedId: parseInt(notification.id) },
      });
      NotificationManager.success("Invitation Declined");
      refetch();
    } catch (error) {}
  };

  useEffect(() => {
    try {
      mutateFunction();
      resetNotificationCount();
    } catch {}
  }, []);

  const resetNotificationCount = () => {
    const data = {
      getNewNotificationsCount: 0,
    };

    // Replace 'notificationCount' with the name of your original query
    client.writeQuery({
      query: GET_NEW_NOTIFICATION_COUNT,
      data,
    });
  };

  if (loading) return <Loader />;
  if (error)
    return (
      <ErrorComponent
        message={error?.message || "An error occurred, please try again"}
      />
    );

  return (
    <div>
      {(accptError || declineError) && (
        <ErrorText
          style={{ marginBottom: "2rem" }}
          message={accptError?.message || declineError?.message}
        />
      )}
      <div>
        {data.notifications.map((item) => {
          return (
            <ListItemWithAction
              loading={acceptLoading || declineLoading}
              notification={item}
              title={item.text}
              acceptClick={() => onAcceptClick(item)}
              declineClick={() => onDeclineClick(item)}
              subtitle={formatUnixTimeStampToFromNow(item.createdAt)}
              showActionButton={
                item.actionCompletedIdToUpdate && !item.isActionTaken
              }
              acceptLoading={acceptLoading}
              declineLoading={declineLoading}
            />
          );
        })}
      </div>
    </div>
  );
}

TeamMessage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withStyles(styles)(TeamMessage);
