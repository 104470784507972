import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Carousel from "react-material-ui-carousel";
import {
  formatDateToMMDDYYY,
  fomatDateFromNow,
  formatUnixTimeStampToFromNow,
} from "../../utils/datetime";
import { useQuery } from "@apollo/client";
import {
  GET_ANNOUNCEMENT_BY_ID,
  GET_EVENT_BY_ID,
  GET_EVENT_BY_ID_WITH_OWNER_DETAIL,
} from "../../graphql/query";
import Loader from "../../components/FullScreenLoader";
import ErrorText from "../../components/Error";

import DescriptionIcon from "@material-ui/icons/Description";
import EventIcon from "@material-ui/icons/Event";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LinkIcon from "@material-ui/icons/Link";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import PersonIcon from "@material-ui/icons/Person";
import ListItemWithDivider from "../Components/list-item-with-divider";

const useStyles = makeStyles((theme) => ({
  root: {},
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function Tab1({ data }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const {
    loading,
    data: announcementData,
    error,
  } = useQuery(GET_ANNOUNCEMENT_BY_ID, {
    variables: { getAnnouncementId: data.entity_id },
  });

  if (loading) return <Loader />;

  if (error) {
    return <ErrorText message={error?.message} />;
  }

  const eventData = announcementData.getAnnouncement;
  const items = eventData.logo.split(",");

  const listData = [
    {
      name: "Description",
      value: eventData.description,
      icon: <DescriptionIcon />,
    },
    {
      name: "Type",
      value: eventData?.announcement_type?.displayText,
      icon: <CallSplitIcon />,
    },
  ];

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            src={items[0]}
            aria-label="recipe"
            className={classes.avatar}
          ></Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={eventData.name}
        subheader={formatUnixTimeStampToFromNow(eventData.createdAt)}
      />

      <CardContent>
        <Carousel>
          {items.map((item, i) => (
            <img src={item} style={{ height: "400px", width: "100%" }} />
          ))}
        </Carousel>
        <ListItemWithDivider data={listData} />
      </CardContent>
    </Card>
  );
}
