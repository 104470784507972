import { gql } from "@apollo/client";

export const SIGN_UP = gql`
  mutation (
    $email: String!
    $firstName: String!
    $lastName: String
    $password: String!
    $roleId: Int!
  ) {
    signup(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      roleId: $roleId
    ) {
      firstName
      lastName
      email
      id
    }
  }
`;

export const SIGN_IN = gql`
  mutation ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        email
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation ($jwt: String!) {
    verifyEmail(jwt: $jwt) {
      token
      user {
        email
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const SET_ALL_NOTIFICATION_TO_VIEWED = gql`
  mutation {
    setAllNotificationToSeen
  }
`;

export const ADD_TO_NETWORK = gql`
  mutation ($email: String, $user_id: Int) {
    addNetworkMember(email: $email, user_id: $user_id) {
      id
      status
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation CreateProject($input: ProjectInput) {
    createProject(input: $input) {
      email
      entity_id
      entity_type
      userid
      name
      firstname
      lastname
      logo
      description
    }
  }
`;

export const GET_PROJECTS = gql`
  query {
    getProjects {
      id
      name
      description
      logo
      role
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation (
    $name: String!
    $description: String!
    $logo: String!
    $eventTypeId: Int
    $projectId: Int
    $link: String
    $date: String
    $time: String
    $isPublic: Boolean!
  ) {
    createEvent(
      name: $name
      description: $description
      logo: $logo
      eventTypeId: $eventTypeId
      projectId: $projectId
      date: $date
      time: $time
      link: $link
      isPublic: $isPublic
    ) {
      email
      entity_id
      entity_type
      userid
      name
      firstname
      lastname
      logo
      description
    }
  }
`;

export const CREATE_ANNOUNCEMENT = gql`
  mutation (
    $name: String!
    $description: String!
    $logo: String!
    $announcementTypeId: Int
    $projectId: Int
    $eventId: Int
  ) {
    createAnnouncement(
      name: $name
      description: $description
      logo: $logo
      announcementTypeId: $announcementTypeId
      projectId: $projectId
      eventId: $eventId
    ) {
      email
      entity_id
      entity_type
      userid
      name
      firstname
      lastname
      logo
      description
    }
  }
`;

export const ADD_PROJECT_MEMBER = gql`
  mutation AddProjectMember($role: String, $userId: Int, $projectId: Int) {
    addProjectMember(role: $role, userId: $userId, projectId: $projectId)
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $phoneNumber: String!
    $firstName: String!
    $lastName: String!
    $description: String!
    $profilePic: String
    $institutionId: Int!
  ) {
    updateUser(
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
      description: $description
      profilePic: $profilePic
      institutionId: $institutionId
    )
  }
`;

export const ACCEPT_NOTIFICATIOn = gql`
  mutation ($notificationAcceptedId: Int!) {
    notificationAccepted(id: $notificationAcceptedId)
  }
`;
export const DECLINE_NOTIFICATION = gql`
  mutation ($notificationDeclinedId: Int!) {
    notificationDeclined(id: $notificationDeclinedId)
  }
`;

export const ADD_USER_TO_EVENT = gql`
  mutation AddUserToEvent($eventId: Int!, $userId: Int!, $role: String!) {
    addUserToEvent(eventId: $eventId, userId: $userId, role: $role) {
      role
      status
      profilePic
      createdAt
      user {
        id
      }
      event {
        id
      }
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation CreateComment(
    $text: String!
    $project_id: Int
    $image: String
    $entity_id: ID!
    $type: String!
  ) {
    createComment(
      text: $text
      project_id: $project_id
      image: $image
      entity_id: $entity_id
      type: $type
    ) {
      text
      image
      user {
        firstName
        lastName
      }
    }
  }
`;

export const UPDATE_USER_EVENT_ROLE = gql`
  mutation UpdateUserEventRole($userEventRoleId: Int!, $role: String!) {
    updateUserEventRole(userEventRoleId: $userEventRoleId, role: $role) {
      id
    }
  }
`;

export const UPDATE_USER_ANNOUNCEMENT_ROLE = gql`
  mutation UpdateUserAnnouncementRole(
    $userAnnouncementRoleId: Int!
    $role: String!
  ) {
    updateUserAnnouncementRole(
      userAnnouncementRoleId: $userAnnouncementRoleId
      role: $role
    ) {
      id
    }
  }
`;

export const DELETE_USER_ANNOUNCEMENT_ROLE = gql`
  mutation DeleteUserAnnouncementRole($userAnnouncementRoleId: Int!) {
    deleteUserAnnouncementRole(userAnnouncementRoleId: $userAnnouncementRoleId)
  }
`;

export const ADD_USER_TO_ANNOUNCEMENT = gql`
  mutation DeleteUserAnnouncementRole(
    $announcementId: Int!
    $userId: Int!
    $role: String!
  ) {
    addUserToAnnouncement(
      announcementId: $announcementId
      userId: $userId
      role: $role
    ) {
      role
      status
      profilePic
      createdAt
      user {
        id
      }
    }
  }
`;

export const CREATE_CONTENT_ITEM = gql`
  mutation CreateContentItem(
    $name: String!
    $description: String!
    $projectId: Int!
    $rationale: String
    $type: String
    $containerType: String!
  ) {
    createContentItem(
      name: $name
      description: $description
      project_id: $projectId
      rationale: $rationale
      type: $type
      containerType: $containerType
    ) {
      name
      description
      rationale
      type
    }
  }
`;

export const CREATE_CONTENT_ITEM_GROUP = gql`
  mutation CreateContentItemGroup(
    $name: String!
    $description: String!
    $projectId: Int!
    $color: String
    $ct1: Int
    $ct2: Int
  ) {
    createContentItemGroup(
      name: $name
      description: $description
      project_id: $projectId
      color: $color
      ct1: $ct1
      ct2: $ct2
    ) {
      name
    }
  }
`;
