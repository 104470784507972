import { useApolloClient, useMutation } from "@apollo/client";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../components/Dialog";
import ErrorText from "../../components/Error";
import { client } from "../../graphql";
import {
  CREATE_ANNOUNCEMENT,
  CREATE_EVENT,
  CREATE_PROJECT,
} from "../../graphql/mutation";
import {
  GET_HOME_FEED,
  GET_PROJECT_EVENT_AND_ANNOUNCEMENT,
} from "../../graphql/query";
import { useUpdateAndInsertCache } from "../../graphql/utils";

import { uploadFile } from "../../utils/uploadFIle";
import { getUserInfoForHomeFeed } from "../../utils/user";
import CreateAnnouncement from "./CreateAnnouncement";
import CreateEvent from "./CreateEvent";
import CreateProject from "./CreateProject";
import { NotificationManager } from "react-notifications";

export default function CreatePost({
  showCreatePostDialog,
  setShowCreatePostDialog,
  showProjectDropdown = true,
  showLinkToProject = true,
  showLinkToEvent = true,
  projectId,
  shouldRefetch,
  setShouldRefetch,
  postType,
}) {
  const [type, setType] = useState(showProjectDropdown ? "project" : "event");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState([]);
  const [successText, setSuccessText] = useState("");
  const [validationError, setValidationError] = useState("");

  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventTypeId, setEventTypeId] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [announcementName, setAnnouncementName] = useState("");
  const [announcementDescription, setAnnouncementDescription] = useState("");
  const [announcementTypeId, setAnnouncementTypeId] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [tags, setTags] = useState([]);
  const [eventLink, setEventLink] = useState("");
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [createProject, { loading, error }] = useMutation(CREATE_PROJECT);
  const [createEvent, { loading: eventLoading, error: eventError }] =
    useMutation(CREATE_EVENT);

  const { updateCache } = useUpdateAndInsertCache();
  useEffect(() => {
    if (!showCreatePostDialog) {
      setType(showProjectDropdown ? "project" : "event");
      setName("");
      setDescription("");
      setFile([]);
      setSuccessText("");
      setValidationError("");

      setEventName("");
      setEventDescription("");
      setEventTypeId("");
      setSelectedProject("");
      setEventDate("");
      setEventTime("");

      setAnnouncementDescription("");
      setAnnouncementName("");
      setAnnouncementTypeId("");
      setSelectedEvent("");
      setEventLink("");
      setFileUploadLoading(false);
      setIsPublic(false);
      setTags([]);
    }
  }, [showCreatePostDialog]);

  useEffect(() => {
    setType(postType);
  }, [postType]);

  const [
    createAnnouncement,
    { loading: announcementLoading, error: announcementError },
  ] = useMutation(CREATE_ANNOUNCEMENT);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleFileChange = (event) => {
    // Handle file input if needed
    let files = [];
    for (var i = 0; i < event.target.files.length; i++) {
      files.push(event.target.files[i]);
    }
    setFile((prev) => [...files]);
  };
  const handleCreateProject = async () => {
    if (!name) {
      setValidationError("Project Name is required");
      return;
    }
    if (!description) {
      setValidationError("Decription is required");
      return;
    }
    if (!file) {
      setValidationError("File is required");
      return;
    }
    setValidationError("");

    const fileUploadedUrl = await uploadFilesAndGetUrl();
    try {
      const { data } = await createProject({
        variables: {
          input: {
            name,
            description,
            logo: fileUploadedUrl,
            isPublic,
            tags: tags.join(","),
            // Include other variables needed for project creation
          },
        },
      });

      NotificationManager.success("Projetc has been created successfully");
      setShouldRefetch(true);
      setShowCreatePostDialog(false);
    } catch (error) {
      console.error("Error creating project:", error);
    }
  };

  const handleEventNameChange = (event) => {
    setEventName(event.target.value);
  };

  const handleEventDescriptionChange = (event) => {
    setEventDescription(event.target.value);
  };

  const handleEventTypeChange = (event) => {
    setEventTypeId(event.target.value);
  };

  const handleSelectedProjectChange = (event) => {
    if (event == null) setSelectedProject(null);
    else setSelectedProject(event.target.value);
  };

  const uploadFilesAndGetUrl = async () => {
    let fileUploadUrl = "";
    setFileUploadLoading(true);
    for (const f of file) {
      const uploaded = await uploadFile(f);
      fileUploadUrl =
        fileUploadUrl == "" ? uploaded : `${fileUploadUrl},${uploaded}`;
    }
    setFileUploadLoading(false);
    return fileUploadUrl;
  };

  const handleCreateEvent = async () => {
    try {
      if (!eventName) {
        setValidationError("Event Name is required");
        return;
      }
      if (!eventDescription) {
        setValidationError("Event Descripion is required");
        return;
      }
      if (!file) {
        setValidationError("Event Logo is required");
        return;
      }
      if (!eventTypeId) {
        setValidationError("Event Type is required");
        return;
      }
      const fileUploadedUrl = await uploadFilesAndGetUrl();
      const dataToCreate = {
        name: eventName,
        description: eventDescription,
        logo: fileUploadedUrl,
        isPublic,
      };

      if (eventTypeId) {
        dataToCreate.eventTypeId = parseInt(eventTypeId);
      }
      if (eventTime) {
        dataToCreate.time = eventTime;
      }
      if (eventDate) {
        dataToCreate.date = eventDate;
      }
      if (eventLink) {
        dataToCreate.link = eventLink;
      }
      if (selectedProject || projectId) {
        dataToCreate.projectId = parseInt(selectedProject || projectId);
      }
      const { data } = await createEvent({ variables: dataToCreate });

      setShouldRefetch(true);
      NotificationManager.success("Event has been created");
      setShowCreatePostDialog(false);
    } catch (error) {}
  };

  const handleCreateAnnouncement = async () => {
    try {
      if (!announcementName) {
        setValidationError("Announcement Name is required");
        return;
      }
      if (!announcementDescription) {
        setValidationError("Announcement Descripion is required");
        return;
      }
      if (!file) {
        setValidationError("Announcement Logo is required");
        return;
      }
      if (!announcementTypeId) {
        setValidationError("Announcement Type is required");
        return;
      }
      const fileUploadedUrl = await uploadFilesAndGetUrl();
      const dataToCreate = {
        name: announcementName,
        description: announcementDescription,
        logo: fileUploadedUrl,
      };

      if (announcementTypeId) {
        dataToCreate.announcementTypeId = parseInt(announcementTypeId);
      }
      if (selectedProject || projectId) {
        dataToCreate.projectId = parseInt(selectedProject || projectId);
      }
      if (selectedEvent) {
        dataToCreate.eventId = parseInt(selectedEvent);
      }

      const { data } = await createAnnouncement({ variables: dataToCreate });
      setShouldRefetch(true);
      NotificationManager.success("Announcement has been created");
      setShowCreatePostDialog(false);
    } catch (error) {}
  };

  return (
    <CustomizedDialogs
      open={showCreatePostDialog}
      onClose={setShowCreatePostDialog}
      loading={
        loading || eventLoading || announcementLoading || fileUploadLoading
      }
      success={successText}
      error={
        error?.message ||
        eventError?.message ||
        announcementError?.message ||
        validationError
      }
      title="Create Post"
      handleSaveClick={() => {
        if (type == "project") handleCreateProject();
        if (type == "event") handleCreateEvent();
        if (type == "announcement") handleCreateAnnouncement();
      }}
      content={
        <>
          <FormControl fullWidth style={{ marginBottom: "1rem" }}>
            <InputLabel variant="outlined" id="demo-simple-select-label">
              Post Type
            </InputLabel>
            <Select
              variant="outlined"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="Age"
              onChange={(e) => setType(e.target.value)}
            >
              {showProjectDropdown && (
                <MenuItem value={"project"}>Project</MenuItem>
              )}
              <MenuItem value={"event"}>Event</MenuItem>
              <MenuItem value={"announcement"}>Announcement</MenuItem>
            </Select>
          </FormControl>
          {type == "project" && (
            <CreateProject
              name={name}
              isPublic={isPublic}
              tags={tags}
              setTags={setTags}
              setIsPublic={setIsPublic}
              description={description}
              handleNameChange={handleNameChange}
              handleDescriptionChange={handleDescriptionChange}
              handleFileChange={handleFileChange}
            />
          )}

          {type == "event" && (
            <CreateEvent
              files={file}
              isPublic={isPublic}
              setIsPublic={setIsPublic}
              showLinkToProject={showLinkToProject}
              handleEventDescriptionChange={handleEventDescriptionChange}
              handleEventNameChange={handleEventNameChange}
              handleEventTypeChange={handleEventTypeChange}
              handleSelectedProjectChange={handleSelectedProjectChange}
              eventDescription={eventDescription}
              eventName={eventName}
              eventTypeId={eventTypeId}
              selectedProject={selectedProject}
              handleFileChange={handleFileChange}
              eventDate={eventDate}
              eventTime={eventTime}
              setEventDate={setEventDate}
              setEventTime={setEventTime}
              eventLink={eventLink}
              setEventLink={setEventLink}
              projectId={projectId}
            />
          )}

          {type == "announcement" && (
            <CreateAnnouncement
              showLinkToProject={showLinkToProject}
              showLinkToEvent={showLinkToEvent}
              handleannouncementDescriptionChange={(e) =>
                setAnnouncementDescription(e.target.value)
              }
              handleannouncementNameChange={(e) =>
                setAnnouncementName(e.target.value)
              }
              handleannouncementTypeChange={(e) =>
                setAnnouncementTypeId(e.target.value)
              }
              handleSelectedProjectChange={handleSelectedProjectChange}
              announcementDescription={announcementDescription}
              announcementName={announcementName}
              announcementTypeId={announcementTypeId}
              selectedProject={selectedProject}
              handleFileChange={handleFileChange}
              selectedEvent={selectedEvent}
              handleSelectedEventChange={(e) =>
                e == null
                  ? setSelectedEvent(null)
                  : setSelectedEvent(e.target.value)
              }
            />
          )}
        </>
      }
    />
  );
}
