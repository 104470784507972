import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import { SmallLoader } from "../../components/FullScreenLoader";
import {
  GET_ANNOUNCEMENT_TYPES,
  GET_announcement_TYPES,
  GET_EVENTS,
} from "../../graphql/query";
import { useQuery } from "@apollo/client";
import { GET_PROJECTS } from "../../graphql/mutation";
import MultiFileUpload from "./MultiFIleUpload";

export default function CreateAnnouncement({
  announcementName,
  handleannouncementNameChange,
  announcementDescription,
  handleannouncementDescriptionChange,
  announcementTypeId,
  handleannouncementTypeChange,
  handleFileChange,
  selectedProject,
  handleSelectedProjectChange,
  selectedEvent,
  handleSelectedEventChange,
  showLinkToProject,
  showLinkToEvent,
}) {
  const [showProjectLink, setShowProjectLink] = useState(false);

  const [showEventLink, setShowEventLink] = useState(false);

  const { data, loading, error } = useQuery(GET_ANNOUNCEMENT_TYPES);
  const {
    data: projects,
    loading: getProjectLoading,
    error: getProjectError,
  } = useQuery(GET_PROJECTS);

  const {
    data: events,
    loading: getEventsLoading,
    error: getEventsError,
  } = useQuery(GET_EVENTS);

  const handleProjectLinkChange = () => {
    setShowProjectLink(true);
    setShowEventLink(false);
    handleSelectedEventChange(null);
  };

  const handleEventLinkChange = () => {
    setShowEventLink(true);
    setShowProjectLink(false);
    handleSelectedProjectChange(null);
  };

  return (
    <Paper>
      <TextField
        style={{ marginBottom: "1rem" }}
        fullWidth
        id="outlined-basic"
        label="Name"
        type="text"
        variant="outlined"
        value={announcementName}
        onChange={handleannouncementNameChange}
      />
      <TextField
        style={{ marginBottom: "1rem" }}
        fullWidth
        id="outlined-basic"
        label="Description"
        type="text"
        multiline
        minRows={4}
        variant="outlined"
        value={announcementDescription}
        onChange={handleannouncementDescriptionChange}
      />

      {loading ? (
        <SmallLoader />
      ) : (
        <FormControl fullWidth style={{ marginBottom: "1rem" }}>
          <InputLabel variant="outlined" id="project-select-label">
            announcement Type
          </InputLabel>
          <Select
            variant="outlined"
            labelId="announcement-type-select-label"
            id="announcement-type-select"
            value={announcementTypeId}
            onChange={handleannouncementTypeChange}
            label="announcement Type"
          >
            {data.announcementType.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.displayText}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <MultiFileUpload setFiles={handleFileChange} />
      {showLinkToProject && (
        <Button
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
          color={showProjectLink ? "primary" : "default"}
          onClick={handleProjectLinkChange}
        >
          Link to Project
        </Button>
      )}
      {showLinkToEvent && (
        <Button
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
          color={showProjectLink ? "primary" : "default"}
          onClick={handleEventLinkChange}
        >
          Link to Event
        </Button>
      )}
      {showProjectLink &&
        (getProjectLoading ? (
          <SmallLoader />
        ) : (
          <FormControl fullWidth style={{ marginBottom: "1rem" }}>
            <InputLabel variant="outlined" id="project-select-label">
              Project
            </InputLabel>
            <Select
              variant="outlined"
              labelId="project-select-label"
              id="project-select"
              value={selectedProject}
              onChange={handleSelectedProjectChange}
              label="Project"
            >
              {projects.getProjects.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
      {showEventLink &&
        (getEventsLoading ? (
          <SmallLoader />
        ) : (
          <FormControl fullWidth style={{ marginBottom: "1rem" }}>
            <InputLabel variant="outlined" id="project-select-label">
              Event
            </InputLabel>
            <Select
              variant="outlined"
              labelId="project-select-label"
              id="project-select"
              value={selectedEvent}
              onChange={handleSelectedEventChange}
              label="Project"
            >
              {events.getEvents.map((item) => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
    </Paper>
  );
}
