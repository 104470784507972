import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import NetworkListItem from "../EventModal/network-list-item";
import { useMutation, useQuery } from "@apollo/client";
import { GET_COMMENTS, GET_EVENT_USERS } from "../../graphql/query";
import Loader, { SmallLoader } from "../../components/FullScreenLoader";
import ErrorText from "../../components/Error";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  AddCircleOutlineRounded,
  Person,
  PersonOutline,
  RemoveCircleOutline,
} from "@material-ui/icons";
import { capitalizeFirstLetter } from "../../utils/stringUtil";
import { ADD_USER_TO_EVENT, CREATE_COMMENT } from "../../graphql/mutation";
import { useUpdateAndInsertCache } from "../../graphql/utils";
import { uploadFile } from "../../utils/uploadFIle";
import SuccessText from "../../components/SuccessText";
import { NotificationManager } from "react-notifications";
import { formatUnixTimeStampToFromNow } from "../../utils/datetime";
import { black, darkBlack, darkGold, gold, lightBlack } from "../../constant";
import { useParams } from "react-router-dom";

const styles = (theme) => ({
  appBar: {
    backgroundColor: lightBlack,
    height: "50px",
  },
  tabContainerInner: {
    // border: '1px solid black',
    background: theme.palette.background.secondary.main,
  },
  listContainer: {
    width: "100%",
  },
  appTool: {
    minHeight: "50px",
    display: "flex",
    justifyContent: "space-between",
  },
  projectListContainer: {
    padding: "5px 15px",
    background: theme.palette.background.primary.main,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
  },
  listHolder: {
    padding: "15px",
  },
  headerContainer: {
    marginBottom: "15px",
    borderBottom: "1px solid #ccc",
  },
  titleName: {
    zIndex: -20,
  },
});

export const Comments = withStyles(styles)(function ({
  classes,
  entity_id,
  type = "event",
}) {
  const [commentText, setCommentText] = useState("");
  const [image, setImage] = useState("");
  const [validationError, setValidationError] = useState(false);

  const params = useParams();

  const variables = {
    entity_id: Number(entity_id),
    type,
  };

  if (params.projectId) {
    variables["project_id"] = params.projectId;
  }
  const {
    data: commentData,
    loading: getCommentLoading,
    error: getCommentError,
    refetch,
  } = useQuery(GET_COMMENTS, {
    variables: variables,
  });

  const [addCommentsDiv, showAddCommentsDiv] = useState(false);
  const [addComment, { loading, error }] = useMutation(CREATE_COMMENT);

  const handleSaveClick = async () => {
    try {
      if (!commentText) {
        setValidationError("Please enetr text");
        return;
      }
      let uploadedFile = null;
      if (image) {
        uploadedFile = await uploadFile(image);
      }
      setValidationError(false);
      let variables = {
        ...variables,
        text: commentText,
        image: uploadedFile || null,
        entity_id: Number(entity_id),
        type: type,
      };

      const { data } = await addComment({
        variables,
      });
      refetch();
      setImage("");
      setCommentText("");
      NotificationManager.success("Created new comment");
    } catch (error) {}
  };
  if (getCommentLoading) return <Loader />;

  if (getCommentError) return <ErrorText message={getCommentError?.message} />;
  return (
    <div>
      {validationError && <ErrorText mb message={validationError} />}
      {error && <ErrorText mb message={error?.message} />}

      <Grid className={classes.tabContainerInner} container spacing={2}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.appTool}>
            <Typography
              variant="subtitle1"
              className={classes.titleName}
              style={{ color: "#000" }}
            >
              Comments
            </Typography>

            <div style={{ display: "flex" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => showAddCommentsDiv(!addCommentsDiv)}
              >
                <Button style={{ background: darkBlack, color: darkGold }}>
                  Add Comments
                </Button>
              </div>
            </div>
          </Toolbar>
        </AppBar>

        {addCommentsDiv && (
          <Card style={{ margin: "1rem", width: "100%" }}>
            <CardContent>
              <TextField
                multiline
                minRows={4}
                style={{ marginBottom: "1rem" }}
                fullWidth
                id="outlined-basic"
                label="Description"
                type="text"
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                variant="outlined"
              />

              <input
                style={{
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  width: "100%",
                  padding: "1rem",
                }}
                onChange={(e) => setImage(e.target.files[0])}
                type="file"
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  autoFocus
                  variant="contained"
                  onClick={handleSaveClick}
                  style={{ background: darkBlack, color: darkGold }}
                >
                  {loading ? (
                    <SmallLoader
                      color={gold}
                      width={20}
                      strokeWidth={5}
                      height={20}
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </CardContent>
          </Card>
        )}

        {commentData.getComment.map((item) => {
          return (
            <Card
              style={{
                marginLeft: "1rem",
                marginRight: "1rem",
                width: "100%",
                borderBottom: "1px solid #ccc",
              }}
            >
              <CardHeader
                avatar={
                  item.user.profilePic ? (
                    item.user.profilePic
                  ) : (
                    <PersonOutline />
                  )
                }
                title={
                  <>
                    <span>
                      {item.user.firstName + " " + item.user.lastName}
                    </span>
                    <span style={{ color: lightBlack, marginLeft: "1rem" }}>
                      {formatUnixTimeStampToFromNow(item.createdAt)}
                    </span>
                  </>
                }
              ></CardHeader>
              <CardContent>
                <div>
                  <Grid container>
                    <Grid item md={6} xs={12}>
                      <Typography variant="body2">{item.text}</Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {item.image && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <img
                            style={{
                              marginTop: "0.5rem",
                              height: "200px",
                              width: "100%",
                            }}
                            src={item.image}
                          />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card>
          );
        })}
      </Grid>
    </div>
  );
});
