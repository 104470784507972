import React, { useEffect, useState } from "react";

import {
  AnnouncementOutlined,
  Chat,
  MoreVert,
  ThumbsUpDownOutlined as ThumbUpIcon,
  ThumbUp,
} from "@material-ui/icons";
import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Icon,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";
import AbsoluteDiv from "../../components/AbsoluteDiv";
import ProjectIcon from "../../assets/project.png";
import AnnouncementIcon from "../../assets/announcement.png";
import EventIcon from "../../assets/event.png";
import { MdEvent, MdEventAvailable } from "react-icons/md";
import { TfiAnnouncement } from "react-icons/tfi";
import { FaDiagramProject } from "react-icons/fa6";
import PostCard from "../../components/PostCard";
import { useQuery } from "@apollo/client";
import { GET_PROJECTS } from "../../graphql/mutation";
import Loader from "../../components/FullScreenLoader";
import ErrorText from "../../components/Error";

import { GET_HOME_FEED } from "../../graphql/query";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import EventModal from "../EventModal";
import AnnouncementModal from "../AnnouncementModal";
import { darkGold, gold, lightGold } from "../../constant";

const Feed = ({
  shouldRefetch,
  setShouldRefetch,
  entity_type_filter = null,
  query = GET_HOME_FEED,
  queryVariables = {},
  queryKey = "getHomeFeed",
}) => {
  const [showEventModal, setShowEventModal] = useState(false);
  const [dataForModal, setDataForModal] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const { data, loading, error, refetch } = useQuery(query, {
    variables: { pageNumber, pageSize, entity_type_filter, ...queryVariables },
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (shouldRefetch) {
      setShouldRefetch(false);
      refetch({
        variables: {
          pageNumber: 1,
          pageSize: 10,
          entity_type_filter,
        },
      });
      setPageNumber(1);
    }
  }, [shouldRefetch]);

  useEffect(() => {
    if (entity_type_filter) {
      refetch({
        variables: {
          pageNumber: 1,
          pageSize: 10,
          entity_type_filter,
        },
      });
      setPageNumber(1);
    }
  }, [entity_type_filter]);

  const onItemClick = (item) => {
    if (item.entity_type == "project") {
      navigate("/project/" + item.entity_id);
    } else if (item.entity_type == "event") {
      setShowEventModal(true);
      setDataForModal((prev) => ({ ...item }));
    } else {
      setShowAnnouncement(true);
      setDataForModal((prev) => ({ ...item }));
    }
  };
  if (loading) return <Loader />;

  if (error)
    return <ErrorText message={error?.message || "Something went wrong"} />;
  return (
    <>
      <EventModal
        dataForModal={dataForModal}
        showModal={showEventModal}
        setShowModal={setShowEventModal}
      />
      <AnnouncementModal
        dataForModal={dataForModal}
        showModal={showAnnouncement}
        setShowModal={setShowAnnouncement}
      />
      {data[queryKey].count == 0 ? (
        <Typography variant="h6" style={{ color: "black" }}>
          There are no post, start by creating a project, event or announcement
        </Typography>
      ) : (
        <div>
          {data[queryKey].data.map((item) => {
            return (
              <Post
                onClick={() => onItemClick(item)}
                item={item}
                icon={<FaDiagramProject fontSize={"1.3rem"} />}
              />
            );
          })}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              onChange={(e, p) => {
                refetch({
                  variables: {
                    pageNumber: p,
                    pageSize: pageSize,
                  },
                });
                setPageNumber(p);
              }}
              page={pageNumber}
              style={{ marginTop: "2rem" }}
              color="primary"
              count={Math.ceil(data[queryKey].count / pageSize)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export const Post = ({ icon, item, onClick }) => {
  return (
    <Paper
      onClick={onClick}
      style={{
        marginBottom: "3rem",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "50%",
          background: "rgba(0,0,0,0.5)",
          borderRadius: "50%",
          paddingLeft: "0.2rem",
          paddingRight: "0.2rem",
          display: "flex",
          padding: "0.5rem",
          top: "-11%",
        }}
      >
        {item.entity_type == "event" ? (
          <MdEvent color={darkGold} fontSize={"1.5rem"} />
        ) : item.entity_type == "project" ? (
          <FaDiagramProject color={darkGold} fontSize={"1.5rem"} />
        ) : (
          <TfiAnnouncement color={darkGold} fontSize={"1.5rem"} />
        )}
      </div>
      <PostCard
        action={
          <>
            {/* <FaDiagramProject fontSize={"1rem"}  /> */}
            {/*  {icon} */}
            <MoreVert />
          </>
        }
        subtitle={item.subtitle || item.firstname + " " + item.lastname}
        title={item.name}
        // imageUrl="https://source.unsplash.com/sWq83ZbZb6U/1600x900"
        avatarUrl={item.logo}
        showLikeAndComment={false}
        body={item.description}
      />
    </Paper>
  );
};

/* const FeedCard = ({icon, img="https://www.nfcr.org/wp-content/uploads/2020/03/Cancer-research-is-t-worth-your-dime.jpg"}) => {
  return (
    <div style={{position:"relative", marginBottom:"2rem"}}>
    <Card sx={{ maxWidth: 400 }}>
      <CardMedia
        component="img"
        alt="Beautiful Image"
        height="400"
        image={img} // Replace with your image URL
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Title
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Description goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <div style={{display:"flex", justifyContent:"space-between", width:"100%", alignItems:"center"}}>
            <div>
        <IconButton aria-label="like">
          <ThumbUp />
          <Typography style={{marginLeft:"0.5rem"}} variant="body2" color="text.secondary">
            42 Likes
          </Typography>
        </IconButton>
        <IconButton aria-label="comment">
          <Chat />
          <Typography style={{marginLeft:"0.5rem"}}  variant="body2" color="text.secondary">
            12 Comments
          </Typography>
        </IconButton>
        </div>
        <div>
        <Button size="small" color="primary">
          More Info
        </Button>
        </div>
        </div>
      </CardActions>
      
        
  
    </Card>
   <AbsoluteDiv right={50} children={icon}  />
   <AbsoluteDiv children={  <MoreVert style={{color:"white"}} color="white" />}  />

    </div>
  )
}
 */
export default Feed;
