import React, { Fragment, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import DemoLink from "./DemoLink.component";
import events, { formatEvent } from "./events-data";
import * as dates from "./dates";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useQuery } from "@apollo/client";
import Loader from "../FullScreenLoader";
import ErrorText from "../Error";

const mLocalizer = momentLocalizer(moment);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: "lightblue",
    },
  });

/**
 * We are defaulting the localizer here because we are using this same
 * example on the main 'About' page in Storybook
 */

const getStartAndEndDateCurrentMonth = () => {
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");

  return { startDate: startOfMonth, endDate: endOfMonth };
};

export default function Basic({
  showDemoLink = true,
  query,
  queryKey,
  onClick,
  ...props
}) {
  const { startDate, endDate } = getStartAndEndDateCurrentMonth();

  const { data, loading, error, refetch } = useQuery(query, {
    variables: { startDate, endDate },
    fetchPolicy: "network-only",
  });

  const handleSelectEvent = (event) => {
    const selectedEvent = data[queryKey].filter(
      (item) => item.id == event.id
    )[0];

    onClick(selectedEvent);
  };

  if (loading) return <Loader />;

  if (error) return <ErrorText message={error?.message} />;
  console.log("ewejhdwqirhihrfihefe", data);
  return (
    <CalendarComponent
      handleSelectEvent={handleSelectEvent}
      refetch={refetch}
      events={formatEvent(data[queryKey])}
    />
  );
}
Basic.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
  showDemoLink: PropTypes.bool,
};

const CalendarComponent = ({
  handleSelectEvent,
  events,
  localizer = mLocalizer,
  refetch,
}) => {
  console.log("events", events);
  return (
    <div className="height600">
      <Calendar
        onSelectEvent={handleSelectEvent}
        localizer={localizer}
        onRangeChange={(dates, type) => {
          console.log(dates);
          console.log(type);
          if (type == "day") {
            const date = moment(dates[0]).format("YYYY-MM-DD");
            refetch({
              variables: {
                startDate: date,
                endDate: date,
              },
            });
          }
          if (type == "agenda" || type == "month") {
            const startDate = moment(dates.start).format("YYYY-MM-DD");
            const endDate = moment(dates.end).format("YYYY-MM-DD");

            refetch({
              variables: {
                startDate: startDate,
                endDate: endDate,
              },
            });
          }
          if (type == "week") {
            const startDate = moment(dates[0]).format("YYYY-MM-DD");
            const endDate = moment(dates[6]).format("YYYY-MM-DD");

            refetch({
              variables: {
                startDate: startDate,
                endDate: endDate,
              },
            });
          }
        }}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, background: "#fff" }}
      />
    </div>
  );
};
